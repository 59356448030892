/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * GA business service support
 * 金算盘接口文档
 * OpenAPI spec version: v1.0.0
 */
import { axiosInstance } from '../index';
export type AutoLoginParams = {
  jsonParam: string;
};

export interface CreateAllocationPlanDto {
  allocationType?: number;
  deptId: number;
  deptName: string;
  indicatorType: number;
  name: string;
  ruleList: AllocationPlanRuleDto[];
  settlementType: string;
  strategyType: number;
  strategyTypeAmount?: number;
  type: number;
}

export interface CreateAllocationPlanGroupDto {
  allocationPlanIds: string;
  deptId: number;
  deptName: string;
  name: string;
  productIds: string;
}

export type ResultListAllocationPlanGroupDtoExtra = { [key: string]: { [key: string]: any } };

export interface ResultListAllocationPlanGroupDto {
  code?: string;
  data?: AllocationPlanGroupDto[];
  extra?: ResultListAllocationPlanGroupDtoExtra;
  message?: string;
  success?: boolean;
}

export type ResultPageResultAllocationPlanGroupDtoExtra = { [key: string]: { [key: string]: any } };

export interface PageResultAllocationPlanGroupDto {
  items?: AllocationPlanGroupDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface ResultPageResultAllocationPlanGroupDto {
  code?: string;
  data?: PageResultAllocationPlanGroupDto;
  extra?: ResultPageResultAllocationPlanGroupDtoExtra;
  message?: string;
  success?: boolean;
}

export interface QueryAllocationPlanGroupDto {
  createAt?: string;
  id?: number;
  name?: string;
  updateAt?: string;
}

export type PageQueryQueryAllocationPlanGroupDtoSorter = { [key: string]: string };

export interface PageQueryQueryAllocationPlanGroupDto {
  pageNo: number;
  pageSize: number;
  query?: QueryAllocationPlanGroupDto;
  sorter?: PageQueryQueryAllocationPlanGroupDtoSorter;
}

export interface UpdateAllocationPlanGroupDto {
  allocationPlanIds: string;
  deptId: number;
  deptName: string;
  id: number;
  name: string;
  productIds: string;
}

export type ResultListAllocationPlanDtoExtra = { [key: string]: { [key: string]: any } };

export interface ResultListAllocationPlanDto {
  code?: string;
  data?: AllocationPlanDto[];
  extra?: ResultListAllocationPlanDtoExtra;
  message?: string;
  success?: boolean;
}

export type ResultPageResultAllocationPlanDtoExtra = { [key: string]: { [key: string]: any } };

export interface PageResultAllocationPlanDto {
  items?: AllocationPlanDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface ResultPageResultAllocationPlanDto {
  code?: string;
  data?: PageResultAllocationPlanDto;
  extra?: ResultPageResultAllocationPlanDtoExtra;
  message?: string;
  success?: boolean;
}

export interface QueryAllocationPlanDto {
  allocationType?: number;
  createAt?: string[];
  deptId?: number;
  id?: number;
  idList?: number[];
  indicatorType?: number;
  name?: string;
  settlementType?: string;
  strategyType?: number;
  type?: number;
  updateAt?: string[];
}

export type PageQueryQueryAllocationPlanDtoSorter = { [key: string]: string };

export interface PageQueryQueryAllocationPlanDto {
  pageNo: number;
  pageSize: number;
  query?: QueryAllocationPlanDto;
  sorter?: PageQueryQueryAllocationPlanDtoSorter;
}

export interface UpdateAllocationPlanDto {
  allocationType?: number;
  deptId: number;
  deptName: string;
  id?: number;
  indicatorType: number;
  name: string;
  ruleList: AllocationPlanRuleDto[];
  settlementType: string;
  strategyType: number;
  strategyTypeAmount?: number;
  type: number;
}

export type ResultPageResultBillAllocationRecordDtoExtra = { [key: string]: { [key: string]: any } };

export interface BillAllocationRecordDto {
  allocationPlanGroupId: number;
  allocationPlanGroupName: string;
  allocationPlanId: number;
  allocationPlanName: string;
  amount: number;
  billId: string;
  createAt: string;
  deptId: number;
  deptName: string;
  goodsCost: number;
  goodsId: string;
  goodsName: string;
  grossProfitAfterTax: number;
  id?: number;
  incentiveAmount: number;
  productId: number;
  productName: string;
}

export interface PageResultBillAllocationRecordDto {
  items?: BillAllocationRecordDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface ResultPageResultBillAllocationRecordDto {
  code?: string;
  data?: PageResultBillAllocationRecordDto;
  extra?: ResultPageResultBillAllocationRecordDtoExtra;
  message?: string;
  success?: boolean;
}

export interface QueryBillAllocationRecordDto {
  allocationPlanGroupId?: number;
  allocationPlanGroupName?: string;
  allocationPlanId?: number;
  allocationPlanName?: string;
  amount?: number;
  billId?: string;
  createAtRange?: string[];
  deptId?: number;
  deptName?: string;
  goodsCost?: number;
  goodsId?: string;
  goodsName?: string;
  grossProfitAfterTax?: number;
  incentiveAmount: number;
  productId?: string;
  productName?: string;
}

export type PageQueryQueryBillAllocationRecordDtoSorter = { [key: string]: string };

export interface PageQueryQueryBillAllocationRecordDto {
  pageNo: number;
  pageSize: number;
  query?: QueryBillAllocationRecordDto;
  sorter?: PageQueryQueryBillAllocationRecordDtoSorter;
}

export interface CreateCostInputRecordDto {
  deptCostDetailList: CreateCostInputRecordDetailDto[];
  month: string;
  remark?: string;
  status: number;
}

export type ResultPageResultCostInputRecordDtoExtra = { [key: string]: { [key: string]: any } };

export interface CostInputRecordDetailDto {
  accountId: number;
  costInputCode: string;
  deleted: boolean;
  deptId: number;
  deptName?: string;
  id?: number;
  loanedWorker?: number;
  other?: number;
  purchaseAsset?: number;
  purchaseOutworker?: number;
  reception?: number;
  salary?: number;
  totalAmount?: number;
  travel?: number;
}

export interface CostInputRecordDto {
  costInputCode: string;
  createAt?: string;
  createdById: number;
  createdByName: string;
  deleted: boolean;
  deptCostDetailList?: CostInputRecordDetailDto[];
  id?: number;
  loanedWorker?: number;
  month: string;
  other?: number;
  purchaseAsset?: number;
  purchaseOutworker?: number;
  reception?: number;
  remark?: string;
  salary?: number;
  status: number;
  totalAmount?: number;
  travel?: number;
  updateAt?: string;
}

export interface PageResultCostInputRecordDto {
  items?: CostInputRecordDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface ResultPageResultCostInputRecordDto {
  code?: string;
  data?: PageResultCostInputRecordDto;
  extra?: ResultPageResultCostInputRecordDtoExtra;
  message?: string;
  success?: boolean;
}

export interface QueryCostInputRecordDto {
  costInputCode?: string;
  month?: string;
  status?: number;
  updateAt?: string[];
}

export type PageQueryQueryCostInputRecordDtoSorter = { [key: string]: string };

export interface PageQueryQueryCostInputRecordDto {
  pageNo: number;
  pageSize: number;
  query?: QueryCostInputRecordDto;
  sorter?: PageQueryQueryCostInputRecordDtoSorter;
}

export interface CreateCostInputRecordDetailDto {
  accountId: number;
  costInputCode: string;
  deleted: boolean;
  deptId: number;
  deptName?: string;
  id?: number;
  loanedWorker?: number;
  other?: number;
  purchaseAsset?: number;
  purchaseOutworker?: number;
  reception?: number;
  salary?: number;
  totalAmount?: number;
  travel?: number;
}

export interface UpdateCostInputRecordDto {
  costInputCode: string;
  deptCostDetailList: CreateCostInputRecordDetailDto[];
  month: string;
  remark?: string;
  status: number;
}

export type ResultListCustomerApiDtoExtra = { [key: string]: { [key: string]: any } };

export interface ResultListCustomerApiDto {
  code?: string;
  data?: CustomerApiDto[];
  extra?: ResultListCustomerApiDtoExtra;
  message?: string;
  success?: boolean;
}

export type ResultListDepartmentApiDtoExtra = { [key: string]: { [key: string]: any } };

export interface DepartmentApiDto {
  createTime?: number;
  id: number;
  isDel?: number;
  level?: number;
  name: string;
  type?: number;
  updateTime?: number;
  upId: number;
}

export interface ResultListDepartmentApiDto {
  code?: string;
  data?: DepartmentApiDto[];
  extra?: ResultListDepartmentApiDtoExtra;
  message?: string;
  success?: boolean;
}

export type ResultListEmployeeApiDtoExtra = { [key: string]: { [key: string]: any } };

export interface ResultListEmployeeApiDto {
  code?: string;
  data?: EmployeeApiDto[];
  extra?: ResultListEmployeeApiDtoExtra;
  message?: string;
  success?: boolean;
}

export interface UpdateEmployeeRoleDto {
  employeeId: number;
  roleType: string;
}

export type ResultPageResultEmployeeSettingDtoExtra = { [key: string]: { [key: string]: any } };

export interface ResultPageResultEmployeeSettingDto {
  code?: string;
  data?: PageResultEmployeeSettingDto;
  extra?: ResultPageResultEmployeeSettingDtoExtra;
  message?: string;
  success?: boolean;
}

export interface EmployeeSettingDto {
  createAt: string;
  customerIds?: string;
  employeeId: number;
  employeeName: string;
  id?: number;
  productIds?: string;
  roleType?: string;
  updateAt: string;
}

export interface PageResultEmployeeSettingDto {
  items?: EmployeeSettingDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface QueryEmployeeRoleDto {
  employeeName?: string;
  roleType?: string;
}

export type PageQueryQueryEmployeeRoleDtoSorter = { [key: string]: string };

export interface PageQueryQueryEmployeeRoleDto {
  pageNo: number;
  pageSize: number;
  query?: QueryEmployeeRoleDto;
  sorter?: PageQueryQueryEmployeeRoleDtoSorter;
}

export type ResultEmployeeApiDtoExtra = { [key: string]: { [key: string]: any } };

export interface ResultEmployeeApiDto {
  code?: string;
  data?: EmployeeApiDto;
  extra?: ResultEmployeeApiDtoExtra;
  message?: string;
  success?: boolean;
}

export interface CustomerApiDto {
  employeeList: EmployeeApiDto[];
  mobile: string;
  name: string;
  userId: string;
}

export interface EmployeeApiDto {
  createTime?: string;
  credential_no?: string;
  credentialType?: number;
  credentialTypeName?: string;
  customerList?: CustomerApiDto[];
  deptName?: string;
  email?: string;
  hrDeptId?: string;
  hrUserId?: string;
  isDel?: number;
  mobile: string;
  name: string;
  postId?: number;
  postName?: string;
  roleType?: string;
  ssoDeptId: number;
  ssoUserId: number;
  updateTime?: string;
  userCode: string;
}

export type ResultListProductAmountDtoExtra = { [key: string]: { [key: string]: any } };

export type ResultHomeDateDtoExtra = { [key: string]: { [key: string]: any } };

export interface ResultHomeDateDto {
  code?: string;
  data?: HomeDateDto;
  extra?: ResultHomeDateDtoExtra;
  message?: string;
  success?: boolean;
}

export interface ProductAmountDto {
  productName?: string;
  totalAmount?: number;
}

export interface ResultListProductAmountDto {
  code?: string;
  data?: ProductAmountDto[];
  extra?: ResultListProductAmountDtoExtra;
  message?: string;
  success?: boolean;
}

export interface HomeDateDto {
  productAmountList?: ProductAmountDto[];
  totalAmountBill?: number;
  totalAmountBillToday?: number;
  totalAmountDept?: number;
  totalAmountDeptToday?: number;
  totalAmountEmployee?: number;
  totalAmountEmployeeToday?: number;
  totalNumberBill?: number;
  totalNumberBillToday?: number;
}

export type ResultPageResultBillAllocationDetailRecordDtoExtra = { [key: string]: { [key: string]: any } };

export interface BillAllocationDetailRecordDto {
  accountId: number;
  allocationPlanGroupId: number;
  allocationPlanGroupName: string;
  allocationPlanId: number;
  allocationPlanName: string;
  allocationPlanRuleId: number;
  allocationPlanRuleName: string;
  allocationPlanRuleRatio?: number;
  amount: number;
  billId: string;
  createAt: string;
  deptId?: number;
  deptName?: string;
  employeeId?: number;
  employeeName?: string;
  goodsCost: number;
  goodsId: string;
  goodsName: string;
  id?: number;
  incentiveAmount: number;
  productId: number;
  productName: string;
  settlementCode?: string;
  settlementStatus: number;
  settlementType: string;
  type: number;
}

export interface PageResultBillAllocationDetailRecordDto {
  items?: BillAllocationDetailRecordDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface ResultPageResultBillAllocationDetailRecordDto {
  code?: string;
  data?: PageResultBillAllocationDetailRecordDto;
  extra?: ResultPageResultBillAllocationDetailRecordDtoExtra;
  message?: string;
  success?: boolean;
}

export interface QueryBillAllocationDetailRecordDto {
  allocationPlanGroupId?: number;
  allocationPlanGroupName?: number;
  allocationPlanId?: number;
  allocationPlanName?: number;
  amount?: number;
  billId?: string;
  createAtRange?: string[];
  deptId?: number;
  deptName?: string;
  employeeId?: number;
  employeeName?: number;
  goodsCost?: number;
  goodsId?: string;
  goodsName?: string;
  grossProfitAfterTax?: number;
  id?: number;
  incentiveAmount?: number;
  productId?: string;
  productName?: string;
}

export type PageQueryQueryBillAllocationDetailRecordDtoSorter = { [key: string]: string };

export interface PageQueryQueryBillAllocationDetailRecordDto {
  pageNo: number;
  pageSize: number;
  query?: QueryBillAllocationDetailRecordDto;
  sorter?: PageQueryQueryBillAllocationDetailRecordDtoSorter;
}

export type ResultListDateAmountDtoExtra = { [key: string]: { [key: string]: any } };

export interface DateAmountDto {
  amount?: number;
  date?: string;
}

export interface ResultListDateAmountDto {
  code?: string;
  data?: DateAmountDto[];
  extra?: ResultListDateAmountDtoExtra;
  message?: string;
  success?: boolean;
}

export interface BillApiDto {
  actualAmount?: number;
  amount?: number;
  appid?: string;
  billDate?: string;
  billEndTime?: string;
  billId?: string;
  billStartTime?: string;
  chargeMode?: string;
  createTime?: string;
  createUid?: string;
  discount?: number;
  goodsId?: string;
  goodsName?: string;
  goodsPriceTypeCode?: string;
  goodsPriceTypeName?: string;
  isvId?: string;
  orderId?: string;
  orderSubId?: string;
  orderType?: number;
  originalPrice?: number;
  partnerId?: string;
  payerName?: string;
  payerUid?: string;
  payTime?: string;
  payType?: string;
  price?: number;
  productId?: string;
  productName?: string;
  unitCode?: string;
  unitCodeCycle?: string;
  unitCodeName?: string;
  unitPrice?: number;
  unitValue?: number;
  unitValueCycle?: number;
}

export type ResultIncentiveAccountDtoExtra = { [key: string]: { [key: string]: any } };

export interface IncentiveAccountDto {
  amount?: number;
  costAmount?: number;
  deptId: number;
  employeeId: number;
  employeeMonthCostAmount?: number;
  id?: number;
  type: number;
}

export interface ResultIncentiveAccountDto {
  code?: string;
  data?: IncentiveAccountDto;
  extra?: ResultIncentiveAccountDtoExtra;
  message?: string;
  success?: boolean;
}

export interface CreateIncentiveGrantingRecordDetailDto {
  accountId?: number;
  amount?: number;
  deptId: number;
  deptName: string;
  employeeId: number;
  employeeName: string;
  ratio?: number;
  remark?: string;
  targetType?: number;
  type?: number;
}

export interface CreateIncentiveGrantingRecordDto {
  deptId?: number;
  deptName?: string;
  incentiveGrantingRecordDetailList: CreateIncentiveGrantingRecordDetailDto[];
  remark?: string;
  status?: number;
}

export type ResultPageResultIncentiveGrantingRecordDtoExtra = { [key: string]: { [key: string]: any } };

export interface IncentiveGrantingRecordDetailDto {
  accountId: number;
  amount?: number;
  deleted: boolean;
  deptId: number;
  deptName: string;
  employeeId: number;
  employeeName?: string;
  id?: number;
  incentiveGrantingCode?: string;
  ratio?: number;
  remark?: string;
  targetType?: number;
  type?: number;
}

export interface UpdateIncentiveGrantingRecordDto {
  deptId?: number;
  deptName?: string;
  incentiveGrantingCode: string;
  incentiveGrantingRecordDetailList: IncentiveGrantingRecordDetailDto[];
  remark?: string;
  status?: number;
}

export interface IncentiveGrantingRecordDto {
  accountId: number;
  amount?: number;
  createAt?: string;
  createdById: number;
  createdByName: string;
  deleted: boolean;
  deptId: number;
  deptName: string;
  id?: number;
  incentiveGrantingCode: string;
  incentiveGrantingRecordDetailList: IncentiveGrantingRecordDetailDto[];
  remark?: string;
  status?: number;
}

export interface PageResultIncentiveGrantingRecordDto {
  items?: IncentiveGrantingRecordDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface ResultPageResultIncentiveGrantingRecordDto {
  code?: string;
  data?: PageResultIncentiveGrantingRecordDto;
  extra?: ResultPageResultIncentiveGrantingRecordDtoExtra;
  message?: string;
  success?: boolean;
}

export interface QueryIncentiveGrantingRecordDto {
  createAt?: string[];
  createdById?: number;
  deptId?: number;
  incentiveGrantingCode?: string;
  remark?: string;
  status?: number;
}

export type PageQueryQueryIncentiveGrantingRecordDtoSorter = { [key: string]: string };

export interface PageQueryQueryIncentiveGrantingRecordDto {
  pageNo: number;
  pageSize: number;
  query?: QueryIncentiveGrantingRecordDto;
  sorter?: PageQueryQueryIncentiveGrantingRecordDtoSorter;
}

export type ResultExtra = { [key: string]: { [key: string]: any } };

export type ResultData = { [key: string]: any };

export interface Result {
  code?: string;
  data?: ResultData;
  extra?: ResultExtra;
  message?: string;
  success?: boolean;
}

export interface UpdateProductSettingDto {
  employeeBlacklist?: string;
  employeeWhitelist?: string;
  id?: number;
  productId: number;
}

export type ResultProductSettingDtoExtra = { [key: string]: { [key: string]: any } };

export interface ProductSettingDto {
  createAt: string;
  employeeBlacklist?: string;
  employeeWhitelist?: string;
  id?: number;
  productId: number;
  updateAt: string;
}

export interface ResultProductSettingDto {
  code?: string;
  data?: ProductSettingDto;
  extra?: ResultProductSettingDtoExtra;
  message?: string;
  success?: boolean;
}

export type ResultListProductApiDtoExtra = { [key: string]: { [key: string]: any } };

export interface ProductApiDto {
  allocationPlanGroupList?: AllocationPlanGroupDto[];
  createTime?: string;
  goodsGroupId?: string;
  goodsGroupName?: string;
  goodsId?: string;
  goodsName?: string;
  productCode?: string;
  productId: number;
  productLineCode?: string;
  productLineId: number;
  productLineName: string;
  productName: string;
  version?: string;
}

export interface ResultListProductApiDto {
  code?: string;
  data?: ProductApiDto[];
  extra?: ResultListProductApiDtoExtra;
  message?: string;
  success?: boolean;
}

export interface AllocationPlanRuleEmployeeDto {
  allocationPlanId?: number;
  allocationPlanRuleId?: number;
  employeeId: number;
  employeeName: string;
  id?: number;
  ratio: number;
}

export interface AllocationPlanRuleDto {
  allocationPlanId?: number;
  deptRatio?: number;
  employeeList: AllocationPlanRuleEmployeeDto[];
  endingMonth: number;
  id?: number;
  name: string;
  startingMonth: number;
}

export interface AllocationPlanDto {
  allocationType?: number;
  createAt: string;
  createdById?: number;
  createdByName?: string;
  deptId: number;
  deptName: string;
  id?: number;
  indicatorType: number;
  name: string;
  ruleList?: AllocationPlanRuleDto[];
  settlementType: string;
  strategyType: number;
  strategyTypeAmount?: number;
  type: number;
  updateAt: string;
  updatedById?: number;
  updatedByName?: string;
}

export interface AllocationPlanGroupDto {
  allocationPlanIds?: string;
  allocationPlanList?: AllocationPlanDto[];
  createAt?: string;
  createdById?: number;
  createdByName?: string;
  deleted?: boolean;
  deptId: number;
  deptName: string;
  id: number;
  name: string;
  productIds?: string;
  productList?: ProductApiDto[];
  updateAt?: string;
  updatedById?: number;
  updatedByName?: string;
}

export interface SettlementRecordDto {
  accountId: number;
  createAt: string;
  employeeId: number;
  employeeName?: string;
  endTime: string;
  id?: number;
  settlementCode?: string;
  settlementPeriod: number;
  startTime: string;
  totalAmount: number;
  type: string;
}

export type ResultPageResultSettlementRecordDtoExtra = { [key: string]: { [key: string]: any } };

export interface PageResultSettlementRecordDto {
  items?: SettlementRecordDto[];
  maxPage?: number;
  pageNo?: number;
  pageSize?: number;
  total?: number;
}

export interface ResultPageResultSettlementRecordDto {
  code?: string;
  data?: PageResultSettlementRecordDto;
  extra?: ResultPageResultSettlementRecordDtoExtra;
  message?: string;
  success?: boolean;
}

export interface QuerySettlementRecordDto {
  accountId?: number;
  createAt?: string;
  employeeId?: number;
  employeeName?: string;
  settlementCode?: string;
  totalAmount?: number;
  type?: string;
}

export type PageQueryQuerySettlementRecordDtoSorter = { [key: string]: string };

export interface PageQueryQuerySettlementRecordDto {
  pageNo: number;
  pageSize: number;
  query?: QuerySettlementRecordDto;
  sorter?: PageQueryQuerySettlementRecordDtoSorter;
}

/**
 * @summary 单点登录接口
 */
export const autoLogin = (params: AutoLoginParams) => {
  return axiosInstance<void>({ url: `/sso/autoLogin`, method: 'POST', params });
};

/**
 * @summary 结算列表(分页)
 */
export const getSettlementPage = (pageQueryQuerySettlementRecordDto: PageQueryQuerySettlementRecordDto) => {
  return axiosInstance<ResultPageResultSettlementRecordDto>({ url: `/settlement/page`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: pageQueryQuerySettlementRecordDto });
};

/**
 * @summary 产品列表
 */
export const getProductList = () => {
  return axiosInstance<ResultListProductApiDto>({ url: `/product/list`, method: 'POST' });
};

/**
 * @summary 设置产品对应的人员名单
 */
export const getProductEmployee = (productId: number) => {
  return axiosInstance<ResultProductSettingDto>({ url: `/product/employee/${productId}`, method: 'POST' });
};

/**
 * @summary 设置产品对应的人员名单
 */
export const saveProductEmployee = (updateProductSettingDto: UpdateProductSettingDto) => {
  return axiosInstance<Result>({ url: `/product/employee/save`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: updateProductSettingDto });
};

/**
 * @summary 成本列表(分页)
 */
export const getIncentiveGrantingPage = (pageQueryQueryIncentiveGrantingRecordDto: PageQueryQueryIncentiveGrantingRecordDto) => {
  return axiosInstance<ResultPageResultIncentiveGrantingRecordDto>({
    url: `/incentive/page`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pageQueryQueryIncentiveGrantingRecordDto,
  });
};

/**
 * @summary 方案激励发放更新(提交/草稿)
 */
export const updateGrantingIncentive = (updateIncentiveGrantingRecordDto: UpdateIncentiveGrantingRecordDto) => {
  return axiosInstance<Result>({ url: `/incentive/incentive/granting/update`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: updateIncentiveGrantingRecordDto });
};

/**
 * @summary 方案激励发放 (提交/草稿)
 */
export const createGrantingIncentive = (createIncentiveGrantingRecordDto: CreateIncentiveGrantingRecordDto) => {
  return axiosInstance<Result>({ url: `/incentive/incentive/granting/create`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createIncentiveGrantingRecordDto });
};

/**
 * @summary 激励发放部门详情
 */
export const getIncentiveAccountDept = () => {
  return axiosInstance<ResultIncentiveAccountDto>({ url: `/incentive/dept/account`, method: 'POST' });
};

/**
 * @summary 激励发放 删除
 */
export const deleteGrantingIncentive = (incentiveGrantingCode: string) => {
  return axiosInstance<Result>({ url: `/incentive/delete/${incentiveGrantingCode}`, method: 'POST' });
};

/**
 * @summary 激励数据创建接口
 */
export const createIncentive = (billApiDto: BillApiDto) => {
  return axiosInstance<void>({ url: `/incentive/create`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: billApiDto });
};

/**
 * @summary 激励发放 提交
 */
export const confirmGrantingIncentive = (incentiveGrantingCode: string) => {
  return axiosInstance<Result>({ url: `/incentive/confirm/${incentiveGrantingCode}`, method: 'POST' });
};

/**
 * @summary 激励发放 撤回
 */
export const cancelGrantingIncentive = (incentiveGrantingCode: string) => {
  return axiosInstance<Result>({ url: `/incentive/cancel/${incentiveGrantingCode}`, method: 'POST' });
};

/**
 * @summary 统计个人近一个月激励金额数据
 */
export const getMonthRecordEmployee = () => {
  return axiosInstance<ResultListDateAmountDto>({ url: `/home/month/record/employee`, method: 'POST' });
};

/**
 * @summary 统计部门近一个月激励金额数据
 */
export const getMonthRecordDept = () => {
  return axiosInstance<ResultListDateAmountDto>({ url: `/home/month/record/dept`, method: 'POST' });
};

/**
 * @summary 统计部门近一个月激励金额数据
 */
export const getDeptEmployeeBillAllocationRecord = (pageQueryQueryBillAllocationDetailRecordDto: PageQueryQueryBillAllocationDetailRecordDto) => {
  return axiosInstance<ResultPageResultBillAllocationDetailRecordDto>({
    url: `/home/employee/allocation/record/page`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pageQueryQueryBillAllocationDetailRecordDto,
  });
};

/**
 * @summary 首页统计接口数据
 */
export const getHomeDate = () => {
  return axiosInstance<ResultHomeDateDto>({ url: `/home/data`, method: 'POST' });
};

/**
 * @summary 账单产品分布数据
 */
export const getBillProductDistribution = (type: number) => {
  return axiosInstance<ResultListProductAmountDto>({ url: `/home/bill/product/distribution/${type}`, method: 'POST' });
};

/**
 * @summary 通过token获取登录员工信息
 */
export const getEmployeeByToken = () => {
  return axiosInstance<ResultEmployeeApiDto>({ url: `/employee/token`, method: 'POST' });
};

/**
 * @summary 员工列表
 */
export const getEmployeeRolePage = (pageQueryQueryEmployeeRoleDto: PageQueryQueryEmployeeRoleDto) => {
  return axiosInstance<ResultPageResultEmployeeSettingDto>({ url: `/employee/role/page`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: pageQueryQueryEmployeeRoleDto });
};

/**
 * @summary 员工列表
 */
export const deleteEmployeeRole = (id: number) => {
  return axiosInstance<Result>({ url: `/employee/role/delete/${id}`, method: 'POST' });
};

/**
 * @summary 员工列表
 */
export const createEmployeeRole = (updateEmployeeRoleDto: UpdateEmployeeRoleDto) => {
  return axiosInstance<Result>({ url: `/employee/role/create`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: updateEmployeeRoleDto });
};

/**
 * @summary 员工列表
 */
export const getEmployeeList = () => {
  return axiosInstance<ResultListEmployeeApiDto>({ url: `/employee/list`, method: 'POST' });
};

/**
 * @summary 员工信息
 */
export const getEmployeeInfo = (employeeId: number) => {
  return axiosInstance<ResultEmployeeApiDto>({ url: `/employee/detail/${employeeId}`, method: 'POST' });
};

/**
 * @summary 获取部门数据
 */
export const getDepartmentList = () => {
  return axiosInstance<ResultListDepartmentApiDto>({ url: `/department/list`, method: 'POST' });
};

/**
 * @summary 获取客户数据
 */
export const getCustomerList = () => {
  return axiosInstance<ResultListCustomerApiDto>({ url: `/customer/list`, method: 'POST' });
};

/**
 * @summary 获取个人的客户数据
 */
export const getPersonalCustomerList = () => {
  return axiosInstance<ResultListCustomerApiDto>({ url: `/customer/list/personal`, method: 'POST' });
};

/**
 * @summary 客户和员工绑定
 */
export const bindingEmployee = (customerId: string) => {
  return axiosInstance<ResultListCustomerApiDto>({ url: `/customer/employee/binding/${customerId}`, method: 'POST' });
};

/**
 * @summary 成本录入 提交/更新草稿
 */
export const updateCostInput = (updateCostInputRecordDto: UpdateCostInputRecordDto) => {
  return axiosInstance<Result>({ url: `/cost/update`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: updateCostInputRecordDto });
};

/**
 * @summary 成本列表(分页)
 */
export const getCostInputPage = (pageQueryQueryCostInputRecordDto: PageQueryQueryCostInputRecordDto) => {
  return axiosInstance<ResultPageResultCostInputRecordDto>({ url: `/cost/page`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: pageQueryQueryCostInputRecordDto });
};

/**
 * @summary 成本录入 删除
 */
export const deleteCostInput = (costInputCode: string) => {
  return axiosInstance<Result>({ url: `/cost/delete/${costInputCode}`, method: 'POST' });
};

/**
 * @summary 成本录入 提交/草稿
 */
export const createCostInput = (createCostInputRecordDto: CreateCostInputRecordDto) => {
  return axiosInstance<Result>({ url: `/cost/create`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createCostInputRecordDto });
};

/**
 * @summary 成本录入 提交
 */
export const confirmCostInput = (costInputCode: string) => {
  return axiosInstance<Result>({ url: `/cost/confirm/${costInputCode}`, method: 'POST' });
};

/**
 * @summary 成本录入 撤回
 */
export const cancelCostInput = (costInputCode: string) => {
  return axiosInstance<Result>({ url: `/cost/cancel/${costInputCode}`, method: 'POST' });
};

/**
 * @summary 方案账单记录
 */
export const getBillAllocationRecordPage = (pageQueryQueryBillAllocationRecordDto: PageQueryQueryBillAllocationRecordDto) => {
  return axiosInstance<ResultPageResultBillAllocationRecordDto>({
    url: `/bill/allocation/record/page`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pageQueryQueryBillAllocationRecordDto,
  });
};

/**
 * @summary 方案账单记录(含员工)
 */
export const getBillAllocationEmployeeRecordPage = (pageQueryQueryBillAllocationDetailRecordDto: PageQueryQueryBillAllocationDetailRecordDto) => {
  return axiosInstance<ResultPageResultBillAllocationDetailRecordDto>({
    url: `/bill/allocation/employee/record/page`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pageQueryQueryBillAllocationDetailRecordDto,
  });
};

/**
 * @summary 更新分配方案
 */
export const updateAllocationPlan = (updateAllocationPlanDto: UpdateAllocationPlanDto) => {
  return axiosInstance<Result>({ url: `/allocation/update`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: updateAllocationPlanDto });
};

/**
 * @summary 分配方案列表(分页)
 */
export const getAllocationPlanPage = (pageQueryQueryAllocationPlanDto: PageQueryQueryAllocationPlanDto) => {
  return axiosInstance<ResultPageResultAllocationPlanDto>({ url: `/allocation/page`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: pageQueryQueryAllocationPlanDto });
};

/**
 * @summary 分配方案列表
 */
export const getAllocationPlanList = (queryAllocationPlanDto: QueryAllocationPlanDto) => {
  return axiosInstance<ResultListAllocationPlanDto>({ url: `/allocation/list`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: queryAllocationPlanDto });
};

/**
 * @summary 更新产品激励
 */
export const updateAllocationPlanGroup = (updateAllocationPlanGroupDto: UpdateAllocationPlanGroupDto) => {
  return axiosInstance<Result>({ url: `/allocation/group/update`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: updateAllocationPlanGroupDto });
};

/**
 * @summary 产品激励列表(分页)
 */
export const getAllocationPlanGroupPage = (pageQueryQueryAllocationPlanGroupDto: PageQueryQueryAllocationPlanGroupDto) => {
  return axiosInstance<ResultPageResultAllocationPlanGroupDto>({
    url: `/allocation/group/page`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pageQueryQueryAllocationPlanGroupDto,
  });
};

/**
 * @summary 产品激励列表
 */
export const getAllocationPlanGroupList = (queryAllocationPlanGroupDto: QueryAllocationPlanGroupDto) => {
  return axiosInstance<ResultListAllocationPlanGroupDto>({ url: `/allocation/group/list`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: queryAllocationPlanGroupDto });
};

/**
 * @summary 新增产品激励
 */
export const createAllocationPlanGroup = (createAllocationPlanGroupDto: CreateAllocationPlanGroupDto) => {
  return axiosInstance<Result>({ url: `/allocation/group/create`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createAllocationPlanGroupDto });
};

/**
 * @summary 新增分配方案
 */
export const createAllocationPlan = (createAllocationPlanDto: CreateAllocationPlanDto) => {
  return axiosInstance<Result>({ url: `/allocation/create`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createAllocationPlanDto });
};

/**
 * @summary 测试登陆接口
 */
export const redirect = () => {
  return axiosInstance<void>({ url: `/test/redirect`, method: 'GET' });
};

/**
 * @summary 测试登陆接口
 */
export const testLogin = () => {
  return axiosInstance<void>({ url: `/test/login`, method: 'GET' });
};

/**
 * @summary 产品激励详情
 */
export const getAllocationPlanGroup = (id: number) => {
  return axiosInstance<ResultListAllocationPlanGroupDto>({ url: `/allocation/group/${id}`, method: 'GET' });
};

/**
 * @summary 删除产品激励
 */
export const deleteAllocationPlanGroup = (id: number) => {
  return axiosInstance<ResultListAllocationPlanDto>({ url: `/allocation/group/delete/${id}`, method: 'GET' });
};

/**
 * @summary 删除分配方案
 */
export const deleteAllocationPlan = (id: number) => {
  return axiosInstance<ResultListAllocationPlanDto>({ url: `/allocation/delete/${id}`, method: 'GET' });
};

export type AutoLoginResult = NonNullable<Awaited<ReturnType<typeof autoLogin>>>;
export type GetSettlementPageResult = NonNullable<Awaited<ReturnType<typeof getSettlementPage>>>;
export type GetProductListResult = NonNullable<Awaited<ReturnType<typeof getProductList>>>;
export type GetProductEmployeeResult = NonNullable<Awaited<ReturnType<typeof getProductEmployee>>>;
export type SaveProductEmployeeResult = NonNullable<Awaited<ReturnType<typeof saveProductEmployee>>>;
export type GetIncentiveGrantingPageResult = NonNullable<Awaited<ReturnType<typeof getIncentiveGrantingPage>>>;
export type UpdateGrantingIncentiveResult = NonNullable<Awaited<ReturnType<typeof updateGrantingIncentive>>>;
export type CreateGrantingIncentiveResult = NonNullable<Awaited<ReturnType<typeof createGrantingIncentive>>>;
export type GetIncentiveAccountDeptResult = NonNullable<Awaited<ReturnType<typeof getIncentiveAccountDept>>>;
export type DeleteGrantingIncentiveResult = NonNullable<Awaited<ReturnType<typeof deleteGrantingIncentive>>>;
export type CreateIncentiveResult = NonNullable<Awaited<ReturnType<typeof createIncentive>>>;
export type ConfirmGrantingIncentiveResult = NonNullable<Awaited<ReturnType<typeof confirmGrantingIncentive>>>;
export type CancelGrantingIncentiveResult = NonNullable<Awaited<ReturnType<typeof cancelGrantingIncentive>>>;
export type GetMonthRecordEmployeeResult = NonNullable<Awaited<ReturnType<typeof getMonthRecordEmployee>>>;
export type GetMonthRecordDeptResult = NonNullable<Awaited<ReturnType<typeof getMonthRecordDept>>>;
export type GetDeptEmployeeBillAllocationRecordResult = NonNullable<Awaited<ReturnType<typeof getDeptEmployeeBillAllocationRecord>>>;
export type GetHomeDateResult = NonNullable<Awaited<ReturnType<typeof getHomeDate>>>;
export type GetBillProductDistributionResult = NonNullable<Awaited<ReturnType<typeof getBillProductDistribution>>>;
export type GetEmployeeByTokenResult = NonNullable<Awaited<ReturnType<typeof getEmployeeByToken>>>;
export type GetEmployeeRolePageResult = NonNullable<Awaited<ReturnType<typeof getEmployeeRolePage>>>;
export type DeleteEmployeeRoleResult = NonNullable<Awaited<ReturnType<typeof deleteEmployeeRole>>>;
export type CreateEmployeeRoleResult = NonNullable<Awaited<ReturnType<typeof createEmployeeRole>>>;
export type GetEmployeeListResult = NonNullable<Awaited<ReturnType<typeof getEmployeeList>>>;
export type GetEmployeeInfoResult = NonNullable<Awaited<ReturnType<typeof getEmployeeInfo>>>;
export type GetDepartmentListResult = NonNullable<Awaited<ReturnType<typeof getDepartmentList>>>;
export type GetCustomerListResult = NonNullable<Awaited<ReturnType<typeof getCustomerList>>>;
export type GetPersonalCustomerListResult = NonNullable<Awaited<ReturnType<typeof getPersonalCustomerList>>>;
export type BindingEmployeeResult = NonNullable<Awaited<ReturnType<typeof bindingEmployee>>>;
export type UpdateCostInputResult = NonNullable<Awaited<ReturnType<typeof updateCostInput>>>;
export type GetCostInputPageResult = NonNullable<Awaited<ReturnType<typeof getCostInputPage>>>;
export type DeleteCostInputResult = NonNullable<Awaited<ReturnType<typeof deleteCostInput>>>;
export type CreateCostInputResult = NonNullable<Awaited<ReturnType<typeof createCostInput>>>;
export type ConfirmCostInputResult = NonNullable<Awaited<ReturnType<typeof confirmCostInput>>>;
export type CancelCostInputResult = NonNullable<Awaited<ReturnType<typeof cancelCostInput>>>;
export type GetBillAllocationRecordPageResult = NonNullable<Awaited<ReturnType<typeof getBillAllocationRecordPage>>>;
export type GetBillAllocationEmployeeRecordPageResult = NonNullable<Awaited<ReturnType<typeof getBillAllocationEmployeeRecordPage>>>;
export type UpdateAllocationPlanResult = NonNullable<Awaited<ReturnType<typeof updateAllocationPlan>>>;
export type GetAllocationPlanPageResult = NonNullable<Awaited<ReturnType<typeof getAllocationPlanPage>>>;
export type GetAllocationPlanListResult = NonNullable<Awaited<ReturnType<typeof getAllocationPlanList>>>;
export type UpdateAllocationPlanGroupResult = NonNullable<Awaited<ReturnType<typeof updateAllocationPlanGroup>>>;
export type GetAllocationPlanGroupPageResult = NonNullable<Awaited<ReturnType<typeof getAllocationPlanGroupPage>>>;
export type GetAllocationPlanGroupListResult = NonNullable<Awaited<ReturnType<typeof getAllocationPlanGroupList>>>;
export type CreateAllocationPlanGroupResult = NonNullable<Awaited<ReturnType<typeof createAllocationPlanGroup>>>;
export type CreateAllocationPlanResult = NonNullable<Awaited<ReturnType<typeof createAllocationPlan>>>;
export type RedirectResult = NonNullable<Awaited<ReturnType<typeof redirect>>>;
export type TestLoginResult = NonNullable<Awaited<ReturnType<typeof testLogin>>>;
export type GetAllocationPlanGroupResult = NonNullable<Awaited<ReturnType<typeof getAllocationPlanGroup>>>;
export type DeleteAllocationPlanGroupResult = NonNullable<Awaited<ReturnType<typeof deleteAllocationPlanGroup>>>;
export type DeleteAllocationPlanResult = NonNullable<Awaited<ReturnType<typeof deleteAllocationPlan>>>;
