import { ActionType, PageContainer, ProColumns, ProFormInstance, ProTable } from '@ant-design/pro-components';
import { Button, message, Popconfirm } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { bindingEmployee, CustomerApiDto, DepartmentApiDto, EmployeeApiDto, getDepartmentList, getEmployeeList, getPersonalCustomerList } from '../../api/service';
import { RootState } from '../../state/store';
import { listToTree } from '../../utils/common';

interface departmentTreeDataType extends DepartmentApiDto {
  id: number;
  key: number;
  title: string;
  upId: number;
}

function Customer() {
  const currentUser = useSelector((state: RootState) => state.app.currentUser);

  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);
  const [employeeDataSource, setEmployeeDataSource] = useState<EmployeeApiDto[]>([]);
  const [currentDeptIds, setCurrentDeptIds] = useState<number[]>();
  const [currentPostIds, setCurrentPostIds] = useState<number[]>();
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const [visible, setVisible] = useState<boolean>(false);
  const updateEmployeeFormRef = useRef<ProFormInstance>();

  const productActionRef = useRef<ActionType>();
  const productFormRef = useRef<ProFormInstance>();

  const customerActionRef = useRef<ActionType>();
  const customerFormRef = useRef<ProFormInstance>();

  const [productSelectedKeys, setProductSelectedKeys] = useState<any[]>();
  const [customerSelectedKeys, setCustomerSelectedKeys] = useState<any[]>();

  async function getDepartmentTreeData() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        const data = result.data;
        // data.forEach(n => n.key = n.id)
        // setDepartmentList(data);
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
      }
    }
  }

  async function doGetEmployeeList() {
    const result = await getEmployeeList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        const data = result.data;
        setEmployeeDataSource(data);
      }
    }
  }

  async function doBindEmployee(item: CustomerApiDto) {
    const result = await bindingEmployee(item.userId);
    if (result.success) {
      message.success('操作成功');
      actionRef.current?.reload();
    }
  }

  // useEffect(() => {
  //   actionRef.current?.reload();
  // }, [employeeDataSource]);

  // useEffect(() => {
  //   doGetEmployeeList();
  // }, [currentDeptId]);

  useEffect(() => {
    getDepartmentTreeData();
  }, []);

  useEffect(() => {
    if (visible) {
      setProductSelectedKeys([]);
      setCustomerSelectedKeys([]);
    } else {
    }
  }, [visible]);

  const columns: ProColumns<CustomerApiDto>[] = [
    {
      title: '客户名称',
      dataIndex: 'name',
    },
    {
      title: '客户手机',
      dataIndex: 'mobile',
    },
    {
      title: '操作',
      key: 'option',
      width: 100,
      align: 'center',
      valueType: 'option',
      render: (text: any, record: any) => {
        return (
          <>
            <Popconfirm title="确定取消绑定该客户吗?" onConfirm={() => doBindEmployee(record)} okText="确定" cancelText="取消">
              <Button type="link">取消绑定</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <PageContainer title="客户列表">
      <ProTable
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'userId'}
        columns={columns}
        toolBarRender={false}
        params={{
          ssoDeptId: currentDeptIds,
          postId: currentPostIds,
        }}
        tableStyle={{
          padding: 24,
        }}
        // dataSource={employeeDataSource}
        request={async (params: any, sorter, filter): Promise<any> => {
          console.log('查询', params, sorter, filter);

          const result = await getPersonalCustomerList();
          if (result?.success) {
            if (result.data && result.data.length > 0) {
              const datasouce = result.data;
              let data: CustomerApiDto[] = datasouce.filter((item) => {
                // 默认通过所有条件
                let passName = true;
                // 值来自于tree
                let passMobile = true;
                // 判断是否有 name 条件，如果有则进行判断
                if (params.name) {
                  passName = item.name?.includes(params.name);
                }
                // 判断是否有 ssoDeptId 条件，如果有则进行判断
                if (params.mobile) {
                  passMobile = params.mobile.includes(item.mobile);
                }
                // 通过所有条件的项将被保留
                return passName && passMobile;
              });
              return {
                data: data,
                success: true,
              };
            }
          }
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
    </PageContainer>
  );
}

export default Customer;
