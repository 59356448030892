import { LeftOutlined } from '@ant-design/icons';
import { Avatar, Button, Layout, Menu, theme } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getEmployeeByToken } from '../api/service';
import { routes } from '../router';
import { setCollapsed, setCurrentUser, setToken } from '../state/app/appSlice';
import { RootState } from '../state/store';
import logo from '../static/images/logo.png';

const { useToken } = theme;
const { Header, Content, Sider } = Layout;

function LayoutWrapper() {
  const darkMode = useSelector((state: RootState) => state.app.darkMode);
  const colorPrimary = useSelector((state: RootState) => state.app.theme.colorPrimary);
  const collapsed = useSelector((state: RootState) => state.app.collapsed);
  const currentUser = useSelector((state: RootState) => state.app.currentUser);
  const dispatch = useDispatch();
  const { token } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [locationHistory, setLocationHistory] = useState<string[]>();
  const [routeList, setRouteList] = useState<any[]>([]);

  const currentYear = new Date().getFullYear();

  // 获取登录用户信息
  const doGetCurrentUser = async () => {
    const result = await getEmployeeByToken();
    if (result.success) {
      console.log('result.data', result.data);
      dispatch(setCurrentUser(result.data));
    }
  };

  useEffect(() => {
    console.log('Route changed to', location);
    locationHistory?.push(location.pathname);
    // 在这里更新页眉或执行其他操作
    // setLocationHistory(locationHistory);
    const queryParams = new URLSearchParams(location.search);
    console.log('queryParams', queryParams);
    const token = queryParams.get('token');
    console.log('token', token);

    if (token) {
      // 通过token换取当前用户登陆信息
      dispatch(setToken(token));
      dispatch(setCurrentUser(null));
      doGetCurrentUser();
      // 获取当前页面的URL，并去掉查询参数部分
      // const baseUrl = window.location.href.split('?')[0];
      // 使用 window.location.replace() 方法刷新页面
      // window.location.replace(baseUrl);
    } else {
      if (!currentUser) {
        doGetCurrentUser();
      }
    }
    console.log('paramValue', token);
  }, [location]);

  const initRouter = () => {
    let _routes = _.cloneDeep(routes);
    let filteredRoutes = _routes.filter((n) => {
      return currentUser.roleType === 'ADMIN' ? !n.access || n.access === 'ADMIN' : !n.access;
    });
    filteredRoutes.map((n) => {
      if (n.children) {
        n.children = n.children.filter((nn) => {
          // @ts-ignore
          return currentUser.roleType === 'ADMIN' ? !nn.access || nn.access === 'ADMIN' : !nn.access;
        });
      }
      return n;
    });
    setRouteList(filteredRoutes);
  };

  useEffect(() => {
    if (currentUser?.ssoUserId) {
      initRouter();
    }
  }, [currentUser]);

  // 使用 useLocation hook 获取当前页面的 location 对象
  // 从 location 对象中获取查询参数

  return (
    <Layout
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 24px',
          background: darkMode ? '' : '#fff',
        }}
      >
        <div
          style={{
            flex: '0 0 200px',
            height: 64,
            // background: token.colorPrimary,
            backgroundImage: `url(${logo})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center center', // 根据需要调整背景位置
            backgroundRepeat: 'no-repeat',
            width: '100%', // 根据需要调整 div 宽度
            color: '#fff',
            textAlign: 'center',
            lineHeight: '40px',
            // marginRight: 20,
          }}
        ></div>
        {/* <Menu
          style={{
            flex: 1,
            display: 'flex',
          }}
          mode="horizontal"
          selectable={false}
          // 顶部菜单
          items={[
            {
              key: '1',
              label: 'Home',
            },
            {
              label: 'Tst',
              key: '2',
              children: [
                {
                  label: 'Tst1',
                  key: '/tst/tst1',
                },
                {
                  label: 'Tst2',
                  key: '/tst/tst2',
                },
              ],
            },
          ]}
        /> */}
        <div style={{ flex: 1, display: 'flex' }}></div>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <ColorPicker
            onChange={(color, hex: string) => {
              console.log(color, hex);
              dispatch(setColorPrimary(hex));
            }}
            format="hex"
            value={colorPrimary}
            presets={[
              {
                label: 'Recommended',
                colors: [
                  '#000000',
                  '#000000E0',
                  '#000000A6',
                  '#00000073',
                  '#00000040',
                  '#00000026',
                  '#0000001A',
                  '#00000012',
                  '#0000000A',
                  '#00000005',
                  '#F5222D',
                  '#FA8C16',
                  '#FADB14',
                  '#8BBB11',
                  '#52C41A',
                  '#13A8A8',
                  '#1677FF',
                  '#2F54EB',
                  '#722ED1',
                  '#EB2F96',
                  '#F5222D4D',
                  '#FA8C164D',
                  '#FADB144D',
                  '#8BBB114D',
                  '#52C41A4D',
                  '#13A8A84D',
                  '#1677FF4D',
                  '#2F54EB4D',
                  '#722ED14D',
                  '#EB2F964D',
                ],
              },
            ]}
          ></ColorPicker>
          <Dropdown
            menu={{
              onClick: (e) => {
                const { key } = e;
                console.log('key', key);
                dispatch(setTheme(key));
              },
              items: [
                {
                  label: '明亮模式',
                  key: 'light',
                },
                {
                  label: '暗黑模式',
                  key: 'dark',
                },
                {
                  label: '跟随系统',
                  key: 'system',
                },
              ],
            }}
          >
            <span>
              <Space>
                <BulbOutlined />
              </Space>
            </span>
          </Dropdown>
        </div> */}
        <Avatar style={{ backgroundColor: token.colorPrimary, verticalAlign: 'middle' }} size="large">
          {currentUser?.name}
        </Avatar>
      </Header>
      <Layout>
        <Sider collapsed={collapsed} style={{}}>
          <Menu
            mode="inline"
            // defaultSelectedKeys={['1']}
            // defaultOpenKeys={['sub1']}
            // inlineCollapsed={collapsed}
            style={{ height: '100%', borderRight: 0 }}
            items={routeList}
            onClick={(e) => {
              console.log(e);
              navigate(e.key);
            }}
          />
          {/* 菜单收缩触发 */}
          <Button
            style={{
              position: 'absolute',
              top: 10,
              right: -12,
              bottom: 0,
              border: 'none',
              backgroundColor: 'rgba(0,0,0,0.06)',
              boxShadow: '0 2px 8px -2px rgba(0,0,0,0.05), 0 1px 4px -1px rgba(25,15,15,0.07), 0 0 1px 0 rgba(0,0,0,0.08)',
              opacity: 0.8,
            }}
            // size="small"
            shape="circle"
            icon={<LeftOutlined rotate={collapsed ? 180 : 0} style={{ fontSize: 12, color: '#333' }} />}
            onClick={() => {
              dispatch(setCollapsed());
            }}
          />
        </Sider>
        <Layout style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {/* <Breadcrumb
            style={{ margin: '12px 0' }}
            items={[
              {
                key: '/home',
                title: 'Home',
              },
              {
                key: 'List',
                title: 'List',
              },
              {
                key: 'App',
                title: 'App',
              },
            ]}
            separator="/"
            // itemRender={(route) => (
            //   <Space>
            //     <Tag closeIcon onClose={() => {}}>
            //       {route.title}
            //     </Tag>
            //   </Space>
            // )}
          ></Breadcrumb> */}
          <Content>
            <Routes>
              {routeList.map((item) => {
                return item.children && item.children.length > 0 ? (
                  // @ts-ignore
                  item.children.map((n) => {
                    return <Route key={n.key} path={n.path} element={n.element}></Route>;
                  })
                ) : (
                  <Route key={item.key} path={item.path} element={item.element}></Route>
                );
              })}
            </Routes>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            © {currentYear} {`多链互联信息科技南京有限公司 苏ICP备2021052264号-1`}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default LayoutWrapper;
