import { ActionType, PageContainer, ProColumns, ProFormInstance, ProTable } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { getSettlementPage, SettlementRecordDto } from '../../api/service';
import { formatProTableQueryData, formatProTableReulstData } from '../../utils/ant-util';

const SettlementType = {
  DAY: '按日',
  MONTH: '按月',
  QUARTER: '按季',
  YEAR: '按年',
};

function AllocationRecordBill() {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();

  useEffect(() => {
    // getAccountBankInfo().then((res) => {
    //   console.log(res.data);
    // });
  }, []);

  const columns: ProColumns<SettlementRecordDto>[] = [
    {
      title: '结算单号',
      dataIndex: 'settlementCode',
    },
    {
      title: '人员名称',
      dataIndex: 'employeeName',
    },
    {
      title: '结算类型',
      dataIndex: 'type',
      valueEnum: SettlementType,
    },
    {
      title: '结算期间',
      dataIndex: 'settlementPeriod',
      hideInSearch: true,
      render: (_, record) => {
        console.log(SettlementType.DAY);
        switch (record.type) {
          case 'DAY':
            return dayjs(record.startTime).set('date', record.settlementPeriod).format('MM-DD');
          case 'MONTH':
            return `${record.settlementPeriod}月`;
          case 'QUARTER':
            return `${record.settlementPeriod}季度`;
          case 'YEAR':
            return `${record.settlementPeriod}年度`;
        }
      },
    },
    {
      title: '结算金额',
      dataIndex: 'totalAmount',
      valueType: 'money',
      hideInSearch: true,
    },
    {
      title: '结算开始时间',
      dataIndex: 'startTime',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '结算结束时间',
      dataIndex: 'endTime',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAtRange',
      valueType: 'dateRange',
      hideInTable: true,
    },
  ];

  return (
    <PageContainer title="结算明细">
      <ProTable
        tableStyle={{
          padding: 24,
        }}
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'id'}
        columns={columns}
        toolBarRender={false}
        dateFormatter="number"
        request={async (params, sorter): Promise<any> => {
          const result = await getSettlementPage(formatProTableQueryData(params));
          return formatProTableReulstData(result);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
    </PageContainer>
  );
}

export default AllocationRecordBill;
