import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ActionType, ModalForm, PageContainer, ProCard, ProColumns, ProFormInstance, ProFormText, ProFormTextArea, ProTable, StatisticCard } from '@ant-design/pro-components';
import { injectGlobal } from '@emotion/css';
import { Button, Cascader, Form, Input, InputNumber, message, Popconfirm, Select, Space, Table, TableColumnsType } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  cancelGrantingIncentive,
  confirmGrantingIncentive,
  createGrantingIncentive,
  deleteGrantingIncentive,
  DepartmentApiDto,
  EmployeeApiDto,
  getDepartmentList,
  getEmployeeList,
  getIncentiveAccountDept,
  getIncentiveGrantingPage,
  IncentiveAccountDto,
  IncentiveGrantingRecordDetailDto,
  IncentiveGrantingRecordDto,
  updateGrantingIncentive,
} from '../../api/service';
import { RootState } from '../../state/store';
import { formatProTableQueryData, formatProTableReulstData } from '../../utils/ant-util';
import { flattenTree, listToTree, numberFormat } from '../../utils/common';

injectGlobal`
  .granting {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
`;

const { Divider } = StatisticCard;

enum Action {
  ADD = '新建激励发放',
  EDIT = '编辑激励发放',
}

interface DeptType extends DepartmentApiDto {
  path: any[];
}

function AllocationRecordBill() {
  const currentUser = useSelector((state: RootState) => state.app.currentUser);

  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const [visible, setVisible] = useState<boolean>(false);
  // 当前操作
  const [currentAction, setCurrentAction] = useState<Action>();
  const [employeeList, setEmployeeList] = useState<EmployeeApiDto[]>([]);
  const [departmentFlattenTreeList, setDepartmentFlattenTreeList] = useState<any[]>([]);
  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);
  const [targetTypeForm, setTargetTypeForm] = useState<any>([]);
  const [incentiveAccountDept, setIncentiveAccountDept] = useState<IncentiveAccountDto>();
  const [currentDept, setCurrentDept] = useState<DeptType>();

  const toAdd = () => {
    setVisible(true);
    setCurrentAction(Action.ADD);
    formRef?.current?.resetFields();
  };

  async function getDepartmentTreeData() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        let data = result.data;
        data = data.filter((n) => n.type === 1);
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
        const deptList = flattenTree(JSON.parse(JSON.stringify(newItem)));
        setDepartmentFlattenTreeList(deptList);
        const currentDept = deptList.find((n: { id: any }) => n.id === currentUser.ssoDeptId);
        setCurrentDept(currentDept);
      }
    }
  }

  async function doGetEmployeeList() {
    const result = await getEmployeeList();
    if (result?.success) {
      let list: EmployeeApiDto[] = result.data || [];
      setEmployeeList(list);
    }
  }

  async function doGetIncentiveAccountDept() {
    const result = await getIncentiveAccountDept();
    if (result?.success) {
      setIncentiveAccountDept(result.data);
    }
  }

  const toEdit = (record: IncentiveGrantingRecordDto) => {
    setVisible(true);
    setCurrentAction(Action.EDIT);
    const _targetTypeForm = record.incentiveGrantingRecordDetailList.map((n, index) => n.targetType);
    setTargetTypeForm(_targetTypeForm);

    const _dept = departmentFlattenTreeList.find((n) => n.id === record.deptId);
    console.log('_dept', _dept?.path?.map((n: { id: any }) => n.id));

    setTimeout(() => {
      formRef.current?.setFieldsValue({
        ...record,
        list: record.incentiveGrantingRecordDetailList.map((n) => {
          // @ts-ignore
          n.deptIds = _dept?.path?.map((n: { id: any }) => n.id);
          return n;
        }),
      });
    });
  };

  const doDelete = async (incentiveGrantingCode: string) => {
    const result = await deleteGrantingIncentive(incentiveGrantingCode);
    if (result?.success) {
      message.success('删除成功');
      actionRef.current?.reload();
    }
  };

  const doConfirm = async (incentiveGrantingCode: string) => {
    const result = await confirmGrantingIncentive(incentiveGrantingCode);
    if (result?.success) {
      message.success('提交成功');
      actionRef.current?.reload();
    }
  };

  const doCancel = async (incentiveGrantingCode: string) => {
    const result = await cancelGrantingIncentive(incentiveGrantingCode);
    if (result?.success) {
      message.success('撤销成功');
      actionRef.current?.reload();
    }
  };

  useEffect(() => {
    if (visible) {
      doGetEmployeeList();
      doGetIncentiveAccountDept();
    }
  }, [visible]);

  useEffect(() => {
    getDepartmentTreeData();
  }, []);

  const columns: ProColumns<IncentiveGrantingRecordDto>[] = [
    {
      title: '记录编号',
      dataIndex: 'incentiveGrantingCode',
    },
    {
      title: '部门名称',
      dataIndex: 'deptName',
      render: (value, record) => {
        const parts = record.deptName?.split('/');
        let result = record.deptName;
        if (parts && parts.length > 2) {
          result = parts.slice(1).join('/');
        }
        return result;
      },
      hideInSearch: true,
    },
    {
      title: '适用部门',
      dataIndex: 'deptId',
      renderFormItem: (item) => {
        return <Cascader options={departmentTreeData} key={item.index} fieldNames={{ label: 'name', value: 'id' }} placeholder="请选择" />;
      },
      hideInTable: true,
    },
    {
      title: '发放金额',
      dataIndex: 'amount',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '状态',
      dataIndex: 'status',
      valueEnum: {
        '-1': {
          text: '作废',
        },
        0: {
          text: '草稿',
        },
        1: {
          text: '正常',
        },
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      valueType: 'dateRange',
      hideInTable: true,
      fieldProps(form, config) {
        return {
          variant: 'outlined',
        };
      },
    },
    {
      title: '操作',
      valueType: 'option',
      render: (_, record) => {
        return (
          <Space>
            {record.status === 0 ? (
              <>
                <Popconfirm title="确定删除吗？" onConfirm={() => doDelete(record.incentiveGrantingCode)}>
                  <a>删除</a>
                </Popconfirm>
                <a onClick={() => toEdit(record)}>编辑</a>
                <Popconfirm title="确定提交吗？" onConfirm={() => doConfirm(record.incentiveGrantingCode)}>
                  <a>提交</a>
                </Popconfirm>
              </>
            ) : (
              <>
                {record.status === 1 ? (
                  <Popconfirm title="确定撤销吗？" onConfirm={() => doCancel(record.incentiveGrantingCode)}>
                    <a>撤销</a>
                  </Popconfirm>
                ) : null}
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const expandedRowRender = (list: any[]) => {
    const columns: TableColumnsType<IncentiveGrantingRecordDetailDto> = [
      {
        title: '接收方名称',
        dataIndex: 'accountId',
        key: 'accountId',
        render: (value, record) => {
          return record.targetType === 1 ? record.employeeName : record.deptName;
        },
      },
      {
        title: '接收方类型',
        dataIndex: 'targetType',
        key: 'targetType',
        render: (value, record) => {
          return record.targetType === 1 ? '员工' : '部门';
        },
      },
      {
        title: '接收金额',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, record) => {
          return numberFormat(record.amount || 0, 2);
        },
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
      },
    ];
    return <Table rowKey={'id'} columns={columns} dataSource={list} pagination={false} />;
  };

  const doSaveIncentiveGrantingDraft = async () => {
    try {
      // 同步代码
      const validateFields = await formRef.current?.validateFields();
    } catch (error) {
      return;
    }

    const values = await formRef.current?.getFieldsValue();
    if (values) {
      const params = {
        ...values,
        incentiveGrantingRecordDetailList: values.list,
        status: 0,
        deptId: currentDept?.id,
        deptName: currentDept?.path.map((n) => n.name).join('/'),
      };
      if (!values.incentiveGrantingCode) {
        const result = await createGrantingIncentive(params);
        if (result.success) {
          message.success('提交成功');
          setVisible(false);
          actionRef.current?.reload();
          return true;
        }
      } else {
        const result = await updateGrantingIncentive(params);
        if (result.success) {
          message.success('提交成功');
          setVisible(false);
          actionRef.current?.reload();
          return true;
        }
      }
    }
  };

  return (
    <PageContainer title="激励发放记录">
      <ProTable
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'id'}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record.incentiveGrantingRecordDetailList),
          defaultExpandedRowKeys: ['0'],
          onExpand: (record) => {
            console.log(record);
          },
        }}
        dateFormatter="number"
        toolBarRender={() => [
          <Button key="primary" type="primary" onClick={toAdd}>
            新增激励发放
          </Button>,
        ]}
        toolbar={{ title: '', settings: [] }}
        request={async (params, sorter): Promise<any> => {
          let query = {
            ...params,
          };
          if (params.deptId) {
            query.deptId = params.deptId[params.deptId.length - 1];
          }
          const result = await getIncentiveGrantingPage(formatProTableQueryData(query));
          return formatProTableReulstData(result);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
      <ModalForm
        title={currentAction}
        open={visible}
        autoFocusFirstInput
        modalProps={{
          onCancel: () => setVisible(false),
        }}
        className="granting"
        submitter={{
          // submitButtonProps: {
          //   name: '提交',
          // },
          render: (props, defaultDoms) => {
            return [
              <Button type="dashed" key="保存草稿" onClick={doSaveIncentiveGrantingDraft}>
                保存草稿
              </Button>,
              ...defaultDoms,
            ];
          },
        }}
        width={'55%'}
        formRef={formRef}
        submitTimeout={2000}
        onFinish={async (values) => {
          const params = {
            ...values,
            incentiveGrantingRecordDetailList: values.list,
            status: 1,
            deptId: currentDept?.id,
            deptName: currentDept?.path.map((n) => n.name).join('/'),
          };

          const totalAmount = values.list.map((n: { amount: any }) => n.amount).reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);
          console.log('totalAmount', totalAmount);

          // 判断余额
          const balance = (incentiveAccountDept?.amount || 0) - (incentiveAccountDept?.costAmount || 0) - (incentiveAccountDept?.employeeMonthCostAmount || 0);
          if (balance - totalAmount < 0) {
            message.error('可发放余额不足');
            return;
          }

          if (!values.incentiveGrantingCode) {
            const result = await createGrantingIncentive(params);
            if (result.success) {
              message.success('提交成功');
              setVisible(false);
              actionRef.current?.reload();
              return true;
            }
          } else {
            const result = await updateGrantingIncentive(params);
            if (result.success) {
              message.success('提交成功');
              setVisible(false);
              actionRef.current?.reload();
              return true;
            }
          }
        }}
      >
        <ProCard gutter={10} bodyStyle={{ paddingInline: 0 }}>
          <StatisticCard.Group gutter={6}>
            <StatisticCard
              style={{ background: '#fff', fontSize: 12 }}
              statistic={{
                valueStyle: { fontSize: 14 },
                title: '所属部门',
                value: `${currentDept?.path
                  ?.filter((n, i) => i !== 0)
                  .map((n) => n.name)
                  .join(' / ')}`,
              }}
            />
            <Divider />
            <StatisticCard
              statistic={{
                title: '可发放余额',
                value: `${numberFormat((incentiveAccountDept?.amount || 0) - (incentiveAccountDept?.costAmount || 0) - (incentiveAccountDept?.employeeMonthCostAmount || 0))}`,
              }}
            />
            <Divider />
            <StatisticCard
              statistic={{
                title: '激励账户余额',
                value: `${numberFormat(incentiveAccountDept?.amount || 0)}`,
              }}
            />
            <Divider />
            <StatisticCard
              statistic={{
                title: '成本账户余额',
                value: `${numberFormat(incentiveAccountDept?.costAmount || 0)}`,
              }}
            />
          </StatisticCard.Group>
        </ProCard>
        <ProCard title="基本信息">
          <ProFormTextArea
            name="remark"
            placeholder="请输入备注"
            rules={[
              {
                required: true,
              },
            ]}
          />
          <ProFormText name="incentiveGrantingCode" hidden />
        </ProCard>
        <ProCard title="发放明细">
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index, getFieldValue) => {
                  const currentField = formRef.current?.getFieldsValue()['list'][index];
                  console.log('currentField', currentField);
                  return (
                    <div key={index}>
                      <Form.Item name={[field.name, 'deptId']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[field.name, 'deptName']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[field.name, 'employeeName']} hidden>
                        <Input />
                      </Form.Item>
                      <Space key={index} style={{ display: 'flex', marginBottom: 8 }}>
                        <Form.Item name={[field.name, 'incentiveGrantingCode']} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[field.name, 'targetType']} rules={[{ required: true, message: '请选择发放对象类型' }]} shouldUpdate>
                          <Select
                            style={{ width: 200 }}
                            onChange={(value) => {
                              console.log(value);
                              targetTypeForm[index] = value;
                              setTargetTypeForm([...targetTypeForm]);
                            }}
                            options={[
                              { value: 1, label: '员工' },
                              { value: 2, label: '部门' },
                            ]}
                            placeholder="请选择发放对象类型"
                          />
                        </Form.Item>
                        {/* 对象类型 */}
                        {targetTypeForm[index] === 1 ? (
                          <>
                            <Form.Item name={[field.name, 'employeeId']} rules={[{ required: true, message: '请选择员工' }]} shouldUpdate>
                              <Select
                                style={{ width: 200 }}
                                fieldNames={{ label: 'name', value: 'ssoUserId' }}
                                options={employeeList}
                                placeholder="请选择员工"
                                onChange={(value, item) => {
                                  console.log(value, item);
                                  let values = formRef.current?.getFieldsValue();
                                  console.log('values', values);
                                  // @ts-ignore
                                  values.list[index].employeeName = item.name;
                                  // todo 同时保存部门信息
                                  // @ts-ignore
                                  values.list[index].deptId = item?.ssoDeptId;

                                  // flattenTree 使用这个拿到路径部门数据
                                  // @ts-ignore
                                  const _dept = departmentFlattenTreeList.find((n) => n.id === item?.ssoDeptId);
                                  if (_dept) {
                                    // @ts-ignore
                                    values.list[index].deptName = _dept.path.map((n) => n.name).join('/');
                                  }
                                  formRef.current?.setFieldsValue({ ...values });
                                }}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item name={[field.name, 'deptIds']} rules={[{ required: true, message: '请选择部门' }]}>
                              <Cascader
                                style={{ width: 200 }}
                                fieldNames={{ label: 'name', value: 'id' }}
                                options={departmentTreeData}
                                changeOnSelect={true}
                                placeholder="请选择发放对象类型"
                                onChange={(ids, items) => {
                                  console.log(ids, items);
                                  let values = formRef.current?.getFieldsValue();
                                  console.log('values', values);
                                  // @ts-ignore
                                  values.list[index].deptId = (ids && ids[ids.length - 1]) || 0;
                                  values.list[index].deptName = (items && items.map((n) => n.name).join('/')) || '';
                                  values.list[index].employeeId = 0;
                                  values.list[index].employeeName = null;
                                  formRef.current?.setFieldsValue({ ...values });
                                }}
                              />
                            </Form.Item>
                          </>
                        )}
                        <Form.Item name={[field.name, 'amount']} rules={[{ required: true, message: '请输入发放金额' }]}>
                          <InputNumber style={{ width: 200 }} placeholder={'请输入发放金额'} />
                        </Form.Item>
                        <Form.Item name={[field.name, 'remark']} rules={[{ required: true, message: '请输入备注' }]}>
                          <Input style={{ width: 200 }} placeholder={'请输入备注'} />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => {
                            targetTypeForm.splice(index, 1);
                            setTargetTypeForm([...targetTypeForm]);
                            remove(field.name);
                          }}
                        />
                      </Space>
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      const _targetForm = [1];
                      setTargetTypeForm([...targetTypeForm, ..._targetForm]);
                      add({ targetType: 1 });
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    添加发放对象
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </ProCard>
      </ModalForm>
    </PageContainer>
  );
}

export default AllocationRecordBill;
