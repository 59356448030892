import _ from 'lodash';

export const listToTree = (list: any[], key: string, parentKey: string) => {
  let map = new Map();
  list.forEach((item) => {
    map.set(item[key], item);
  });
  // console.log('obj', obj);
  const parentList: any[] = [];
  list.forEach((item) => {
    const parent = map.get(item[parentKey]);
    if (parent) {
      // * 当前项有父节点
      parent.children = parent.children || [];
      parent.children.push(item);
    } else {
      // * 当前项没有父节点 -> 顶层
      parentList.push(item);
    }
  });
  return parentList;
};

export const numberFormat = (value: number, precision?: number) => {
  // eslint-disable-next-line no-param-reassign
  value = _.round(value, 2);
  return new Intl.NumberFormat('zh-CN', { style: 'currency', currency: 'CNY', minimumFractionDigits: precision || 0, maximumFractionDigits: 2 }).format(value);
};

export const findParentIds = (nodes: any[], targetId: number): any[] => {
  const currentNode = nodes.find((node) => node.id === targetId);

  if (!currentNode || currentNode.upId === undefined) {
    return [];
  }

  const parentAncestors = findParentIds(nodes, currentNode.upId);
  return [currentNode, ...parentAncestors];
};

export const findChildIds = (nodes: any[], parentId: number): any[] => {
  const childNodes = nodes.filter((node) => node.upId === parentId);

  if (childNodes.length === 0) {
    return [];
  }

  const allChildren = childNodes.flatMap((childNode) => {
    const grandchildren = findChildIds(nodes, childNode.id);
    return [childNode, ...grandchildren];
  });

  return allChildren;
};

export const flattenTree = (treeData: any) => {
  const result: any = [];

  function flatten(node: any, level = 0, path: any[] = []) {
    const { children, ...rest } = node;
    const newPath = [...path, rest]; // 将当前节点添加到路径中
    result.push({ ...rest, level, path: newPath });

    if (children && children.length > 0) {
      children.forEach((child: any) => flatten(child, level + 1, newPath));
    }
  }
  treeData.forEach((node: any) => flatten(node));
  return result;
};
