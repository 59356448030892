import { ActionType, PageContainer, ProColumns, ProFormInstance, ProTable } from '@ant-design/pro-components';
import { Cascader, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { BillAllocationDetailRecordDto, getBillAllocationEmployeeRecordPage, getDepartmentList } from '../../../api/service';
import { formatProTableQueryData, formatProTableReulstData } from '../../../utils/ant-util';
import { listToTree } from '../../../utils/common';

function AllocationRecordBillEmployee() {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);

  async function getDepartmentTreeData() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        let data = result.data;
        data = data.filter((n) => n.type === 1);
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
      }
    }
  }

  useEffect(() => {
    getDepartmentTreeData();
  }, []);

  const columns: ProColumns<BillAllocationDetailRecordDto>[] = [
    {
      title: '账单编号',
      dataIndex: 'billId',
      render: (_, record) => {
        return (
          <Tooltip title={record.billId}>
            <span>{'...' + record.billId.slice(-4)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '激励对象',
      dataIndex: 'employeeName',
      render: (_, record) => {
        return record.type === 1 ? record.employeeName : record.deptName;
      },
    },
    {
      title: '对象类型',
      dataIndex: 'type',
      valueEnum: {
        1: '员工',
        2: '部门',
      },
    },
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      ellipsis: true,
      width: 200,
    },
    {
      title: '产品名称',
      dataIndex: 'productName',
      // hideInSearch: true,
    },
    // {
    //   title: '商品成本',
    //   dataIndex: 'goodsCost',
    //   // hideInSearch: true,
    // },
    {
      title: '采购成本',
      dataIndex: 'goodsCost',
      valueType: 'money',
      hideInSearch: true,
    },
    {
      title: '部门名称',
      dataIndex: 'deptName',
      ellipsis: true,
      render: (value, record) => {
        const parts = record.deptName?.split('/');
        let result = record.deptName;
        if (parts && parts.length > 2) {
          result = parts.slice(1).join('/');
        }
        return result;
      },
      hideInSearch: true,
    },
    {
      title: '部门名称',
      dataIndex: 'deptId',
      renderFormItem: (item) => {
        return <Cascader options={departmentTreeData} key={item.index} fieldNames={{ label: 'name', value: 'id' }} placeholder="请选择" />;
      },
      hideInTable: true,
    },
    {
      title: '方案名称',
      dataIndex: 'allocationPlanName',
    },
    {
      title: '激励名称',
      dataIndex: 'allocationPlanGroupName',
    },
    {
      title: '奖励比例',
      dataIndex: 'allocationPlanRuleRatio',
      hideInSearch: true,
      render: (_, record) => {
        return record.allocationPlanRuleRatio + '%';
      },
    },
    {
      title: '奖励金额',
      dataIndex: 'incentiveAmount',
      valueType: 'money',
      hideInSearch: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      valueType: 'dateTime',
      hideInSearch: true,
      width: 200,
    },
    {
      title: '创建时间',
      dataIndex: 'createAtRange',
      valueType: 'dateRange',
      hideInTable: true,
      fieldProps(form, config) {
        return {
          variant: 'outlined',
        };
      },
    },
  ];

  return (
    <PageContainer title="激励明细">
      <ProTable
        tableStyle={{
          padding: 24,
        }}
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'id'}
        columns={columns}
        toolBarRender={false}
        dateFormatter="number"
        request={async (params, sorter): Promise<any> => {
          let query = {
            ...params,
          };
          if (params.deptId) {
            query.deptId = params.deptId[params.deptId.length - 1];
          }
          const result = await getBillAllocationEmployeeRecordPage(formatProTableQueryData(query));
          return formatProTableReulstData(result);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
    </PageContainer>
  );
}

export default AllocationRecordBillEmployee;
