import { CloseOutlined } from '@ant-design/icons';
import {
  ActionType,
  ModalForm,
  PageContainer,
  ProCard,
  ProColumns,
  ProFormCascader,
  ProFormDependency,
  ProFormDigit,
  ProFormGroup,
  ProFormInstance,
  ProFormMoney,
  ProFormSelect,
  ProFormText,
  ProTable,
} from '@ant-design/pro-components';
import { injectGlobal } from '@emotion/css';
import { Button, Card, Cascader, Col, Form, Input, InputNumber, message, Popconfirm, Row, Space, theme } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  AllocationPlanDto,
  AllocationPlanRuleDto,
  createAllocationPlan,
  CreateAllocationPlanDto,
  deleteAllocationPlan,
  EmployeeApiDto,
  getAllocationPlanPage,
  getDepartmentList,
  getEmployeeList,
  Result,
  updateAllocationPlan,
  UpdateAllocationPlanDto,
} from '../../../api/service';
import { formatProTableQueryData, formatProTableReulstData } from '../../../utils/ant-util';
import { findParentIds, listToTree } from '../../../utils/common';
const { useToken } = theme;

injectGlobal`
  .ant-form-vertical .ant-form-item-row {
    flex-direction: inherit !important;
  }
`;

enum Action {
  ADD = '新建分配方案',
  EDIT = '编辑分配方案',
}

type EmployeeTableType = {
  // actionRef: React.MutableRefObject<ActionType | undefined>;
  // formRef: React.MutableRefObject<ProFormInstance | undefined>;
  dataSource: AllocationPlanRuleDto[];
};

interface AllocationPlanDatasource extends AllocationPlanDto {
  deptIds: [];
}

function AllocationPlanList() {
  const actionRef = useRef<ActionType>();

  const formRef = useRef<ProFormInstance>();
  const [visible, setVisible] = useState<boolean>(false);
  // 当前操作
  const [currentAction, setCurrentAction] = useState<Action>();
  const [currentDept, setCurrentDept] = useState<any>();
  const [currentDeptIds, setCurrentDeptIds] = useState<number[]>([]);
  const [employeeList, setEmployeeList] = useState<EmployeeApiDto[]>([]);
  const [employeeDataSourceList, setEmployeeDataSourceList] = useState<EmployeeTableType[]>([]);

  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);
  const [currentAllocationPlan, setCurrentAllocationPlan] = useState<AllocationPlanDto>();

  const [allocationRuleListForm] = Form.useForm();

  const { token } = useToken();

  const toAdd = () => {
    setVisible(true);
    setCurrentAction(Action.ADD);
    formRef?.current?.resetFields();
  };

  const toEdit = (record: AllocationPlanDto) => {
    setVisible(true);
    setCurrentAllocationPlan(record);
    setCurrentAction(Action.EDIT);
    const dept = departmentList.find((n) => n.id === record.deptId);
    setCurrentDept(dept);
    const deptList = findParentIds(departmentList, record.deptId);
    const _deptIds = deptList.map((n) => n.id).sort((a, b) => a - b);
    // 通过部门获取人员信息
    setCurrentDeptIds(_deptIds);
    // 生成人员规则数据
    setTimeout(() => {
      const defaultParams = {
        ...record,
        deptIds: _deptIds,
      };
      formRef?.current?.setFieldsValue(defaultParams);
    }, 100);
  };

  const doDelete = async (id: number) => {
    const result = await deleteAllocationPlan(id);
    if (result?.success) {
      message.success('删除成功');
      actionRef.current?.reload();
    }
  };

  async function getDepartmentTreeData() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        let data = result.data;
        data = data.filter((n) => n.type === 1);
        setDepartmentList(data);
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
      }
    }
  }

  async function doGetEmployeeList() {
    const result = await getEmployeeList();
    if (result?.success) {
      let list: EmployeeApiDto[] = result.data || [];
      addAllocationRuleList(list, currentDeptIds);
    }
  }

  const addAllocationRuleList = (empList: EmployeeApiDto[], deptIds: number[]) => {
    console.log('empList', empList, deptIds);

    let list = empList;
    if (deptIds && deptIds.length > 0) {
      list = list.filter((item) => {
        return deptIds.includes(item.ssoDeptId);
      });
    } else {
      list = [];
    }
    if (list.length > 0) {
      let data = allocationRuleListForm.getFieldsValue();
      if (data.ruleList.length > 0) {
        let _data = JSON.parse(JSON.stringify(data.ruleList[data.ruleList.length - 1]));
        _data.startingMonth = _data.endingMonth ? Number(_data.endingMonth) + 1 : '';
        _data.endingMonth = '';
        _data.id = '';
        _data.employeeList.forEach((n: { ratio: string; id: any; allocationPlanRuleId: any }) => {
          n.ratio = '';
          n.id = '';
        });
        data.ruleList.push(_data);
        allocationRuleListForm.setFieldsValue({ ...data });
        console.log('add');
      } else {
        if (Action.ADD === currentAction) {
          let employeeList = list.map((n) => {
            return {
              employeeId: n.ssoUserId,
              employeeName: n.name,
              ratio: '',
            };
          });
          allocationRuleListForm.setFieldsValue({
            ruleList: [
              {
                startingMonth: '',
                endingMonth: '',
                deptRatio: '',
                employeeList: employeeList,
              },
            ],
          });
          console.log('add1');
        } else {
          // 通过开始月结束月进行分组 并且通过开始月进行排序, 然后将值进行复制
          if (currentAllocationPlan && currentAllocationPlan.ruleList && currentAllocationPlan.ruleList.length > 0) {
            allocationRuleListForm.setFieldsValue({
              ruleList: [...currentAllocationPlan.ruleList],
            });
            console.log('add2');
          } else {
            let employeeList = list.map((n) => {
              return {
                employeeId: n.ssoUserId,
                employeeName: n.name,
                ratio: '',
              };
            });
            allocationRuleListForm.setFieldsValue({
              ruleList: [
                {
                  startingMonth: '',
                  endingMonth: '',
                  deptRatio: '',
                  employeeList: employeeList,
                },
              ],
            });
          }
        }
      }
    }
    setEmployeeList(list);
  };

  useEffect(() => {
    allocationRuleListForm.setFieldsValue({
      ruleList: [],
    });
    if (currentAllocationPlan) {
      setCurrentAllocationPlan({
        ...currentAllocationPlan,
        ruleList: [],
      });
    }
    setTimeout(() => {
      doGetEmployeeList();
    });
  }, [currentDeptIds]);

  useEffect(() => {
    getDepartmentTreeData();
  }, []);

  useEffect(() => {
    console.log('employeeDataSourceList', employeeDataSourceList);
  }, [employeeDataSourceList]);

  useEffect(() => {
    // 初始化方案详情
    if (visible) {
    } else {
      setCurrentDeptIds([]);
      setCurrentDept(null);
      setEmployeeList([]);
    }
  }, [visible]);

  const columns: ProColumns<AllocationPlanDatasource>[] = [
    {
      title: '方案名称',
      dataIndex: 'name',
    },
    {
      title: '适用类型',
      dataIndex: 'type',
      valueEnum: {
        1: {
          text: '个人',
        },
        2: {
          text: '部门',
        },
      },
    },
    {
      title: '适用部门',
      dataIndex: 'deptName',
      hideInSearch: true,
      render: (value, record) => {
        const parts = record.deptName?.split('/');
        let result = record.deptName;
        if (parts && parts.length > 2) {
          result = parts.slice(1).join('/');
        }
        return result;
      },
    },
    {
      title: '适用部门',
      dataIndex: 'deptId',
      renderFormItem: (item) => {
        return <Cascader options={departmentTreeData} key={item.index} fieldNames={{ label: 'name', value: 'id' }} placeholder="请选择" />;
      },
      hideInTable: true,
    },
    {
      title: '结算周期',
      dataIndex: 'settlementType',
      valueEnum: {
        DAY: {
          text: '按日',
        },
        MONTH: {
          text: '按月',
        },
        QUARTER: {
          text: '按季',
        },
        YEAR: {
          text: '按年',
        },
      },
    },
    // {
    //   title: '执行优先级',
    //   dataIndex: 'level',
    //   valueEnum: {
    //     1: {
    //       text: '一级',
    //     },
    //     2: {
    //       text: '二级',
    //     },
    //     3: {
    //       text: '三级',
    //     },
    //     4: {
    //       text: '四级',
    //     },
    //   },
    // },
    {
      title: '锚定指标',
      dataIndex: 'indicatorType',
      valueEnum: {
        1: {
          text: '除税毛利',
        },
        2: {
          text: '实收金额',
        },
        3: {
          text: '除税实收',
        },
      },
    },
    {
      title: '锚定策略',
      dataIndex: 'strategyType',
      valueEnum: {
        1: {
          text: '动态比例',
        },
        2: {
          text: '固定金额',
        },
        3: {
          text: '固定比例',
        },
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updateAt',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '更新时间',
      dataIndex: 'updateAt',
      valueType: 'dateRange',
      fieldProps(form, config) {
        return {
          variant: 'borderless',
        };
      },
      hideInTable: true,
      formItemProps: {},
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      valueType: 'dateRange',
      hideInTable: true,
      fieldProps(form, config) {
        return {
          variant: 'outlined',
        };
      },
    },
    {
      title: '编辑人',
      dataIndex: 'updatedByName',
      align: 'center',
      hideInSearch: true,
    },
    {
      title: '操作',
      valueType: 'option',
      render: (_, record) => {
        return (
          <Space>
            <a onClick={() => toEdit(record)}>编辑</a>
            <Popconfirm title="确定删除吗？" onConfirm={() => doDelete(record.id as number)}>
              <a>删除</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <PageContainer title="方案列表">
      <ProTable
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'id'}
        columns={columns}
        dateFormatter="number"
        toolBarRender={() => [
          <Button key="primary" type="primary" onClick={toAdd}>
            新增方案
          </Button>,
        ]}
        toolbar={{ title: '', settings: [] }}
        request={async (params, sorter): Promise<any> => {
          let query = {
            ...params,
          };
          if (params.deptId) {
            query.deptId = params.deptId[params.deptId.length - 1];
          }
          console.log('query', query);

          const result = await getAllocationPlanPage(formatProTableQueryData(query));
          return formatProTableReulstData(result);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
      <ModalForm<{ deptIds: [] } & UpdateAllocationPlanDto>
        title={currentAction}
        open={visible}
        autoFocusFirstInput
        modalProps={{
          onCancel: () => setVisible(false),
        }}
        width={'70%'}
        formRef={formRef}
        submitTimeout={2000}
        onFinish={async (values) => {
          console.log('values', values);
          console.log('values', values);

          // 验证动态表单中数据
          try {
            // 同步代码
            const validateFields = await allocationRuleListForm?.validateFields();

            console.log('validateFields', validateFields);
          } catch (error) {
            console.error('分配规则为空', error);
            return;
          }

          // 获取动态表单中数据
          const formData = allocationRuleListForm.getFieldsValue();
          // 动态表单数据转换
          console.log('data', formData.ruleList);
          if (formData.ruleList.length === 0) {
            message.error('请添加分配规则');
            return;
          }

          let _formData = JSON.parse(JSON.stringify(formData));
          _formData.ruleList.forEach((n: any) => {
            if (values.type === 1) {
              n.deptRatio = '';
            } else {
              n.employeeList = [];
            }
          });
          let result: Result = {};
          if (currentAction === Action.ADD) {
            const params: CreateAllocationPlanDto = {
              ...values,
              deptId: values.deptIds[values.deptIds.length - 1],
              ..._formData,
            };
            console.log(' params', params);
            result = await createAllocationPlan(params);
          } else {
            const params: UpdateAllocationPlanDto = {
              ...values,
              deptId: values.deptIds[values.deptIds.length - 1],
              ..._formData,
            };
            console.log(' params', params);
            result = await updateAllocationPlan(params);
          }
          if (result?.success) {
            message.success('操作成功');
            setVisible(false);
            actionRef.current?.reload();
          } else {
            // formRef.current?.setFieldsValue(values);
          }
          return true;
        }}
      >
        <ProFormText name="id" hidden />
        <ProFormText name="deptName" hidden />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ProFormText name="name" label="方案名称" placeholder="方案名称" rules={[{ required: true, message: '请输入方案名称' }, { max: 50 }]} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <ProFormSelect
              name="type"
              label="方案适用类型"
              request={async () => [
                { label: '个人', value: 1 },
                { label: '部门', value: 2 },
              ]}
              onChange={(value) => {
                if (!value) {
                  formRef.current?.setFieldValue('deptIds', []);
                  setCurrentDeptIds([]);
                }
              }}
              placeholder="请选择方案适用类型"
              rules={[{ required: true, message: '请选择方案适用类型' }]}
            />
          </Col>
          <ProFormDependency name={['type']}>
            {({ type }) => {
              return (
                <Col span={8}>
                  <ProFormCascader
                    name="deptIds"
                    label="适用部门"
                    disabled={!type}
                    fieldProps={{
                      variant: 'outlined',
                      fieldNames: {
                        label: 'name',
                        value: 'id',
                      },
                      onChange: (value: any, selectOptions: any) => {
                        if (value) {
                          console.log(' value', value, selectOptions);
                          setCurrentDept(selectOptions[selectOptions.length - 1]);
                          setCurrentDeptIds(value);
                          let _deptName = selectOptions.map((n: { name: any }) => n.name).join('/');
                          console.log('_deptName', _deptName);
                          formRef.current?.setFieldValue('deptName', _deptName);
                        } else {
                          setCurrentDept(null);
                          setCurrentDeptIds([]);
                          formRef.current?.setFieldValue('deptName', '');
                        }
                      },
                    }}
                    request={async (params, sorter): Promise<any> => {
                      console.log(' departmentTreeData', departmentTreeData);
                      return departmentTreeData;
                    }}
                    placeholder="请选择发票税率"
                    rules={[{ required: true, message: '请选择发票税率' }]}
                  />
                </Col>
              );
            }}
          </ProFormDependency>

          <Col span={8}>
            <ProFormSelect
              name="settlementType"
              label="结算周期"
              valueEnum={{
                DAY: '按日',
                MONTH: '按月',
                QUARTER: '按季',
                YEAR: '按年',
              }}
              placeholder="请选择结算周期"
              rules={[{ required: true, message: '请选择结算周期' }]}
            />
          </Col>
          <Col span={8}>
            <ProFormSelect
              name="indicatorType"
              label="锚定指标"
              tooltip="针对账单金额"
              request={async () => [
                { label: '除税毛利', value: 1 },
                // { label: '实收金额', value: 2 },
                // { label: '除税实收', value: 3 },
              ]}
              placeholder="请选择锚定指标"
              rules={[{ required: true, message: '请选择锚定指标' }]}
            />
          </Col>
          <Col span={8}>
            <ProFormSelect
              name="strategyType"
              label="锚定策略"
              tooltip={
                <div>
                  <p>动态比例: 按照人员分配规则进行激励分配</p>
                  <p>固定金额: 按照固定金额进行激励分配,</p>
                  <p>固定比例: 按照固定比例进行激励分配</p>
                </div>
              }
              request={async () => [
                { label: '动态比例', value: 1 },
                // { label: '固定金额', value: 2 },
                // { label: '固定比例', value: 3 },
              ]}
              placeholder="请选择锚定策略"
              rules={[{ required: true, message: '请选择锚定策略' }]}
            />
          </Col>
          <ProFormDependency name={['strategyType']}>
            {({ strategyType }) => {
              switch (strategyType) {
                case 2:
                  return (
                    <Col span={8}>
                      <ProFormMoney name="strategyTypeAmount" label="固定金额" placeholder="请输入金额" rules={[{ required: true, message: '请输入金额' }]} fieldProps={{ addonAfter: '元' }} />
                    </Col>
                  );
                case 3:
                  return (
                    <Col span={8}>
                      <ProFormDigit
                        name="strategyTypeAmount"
                        label="固定比例"
                        placeholder="请输入比例"
                        min={0}
                        max={100}
                        rules={[{ required: true, message: '请输入固定比例' }]}
                        fieldProps={{ addonAfter: '%' }}
                      />
                    </Col>
                  );
                default:
                  return null;
              }
            }}
          </ProFormDependency>
          <ProFormDependency name={['type']}>
            {({ type }) => {
              return type === 1 ? (
                <Col span={8}>
                  <ProFormSelect
                    name="allocationType"
                    label="分配范围"
                    tooltip={
                      <div>
                        <p>全员分配: 表示所有关联的人员都参与到激励分配</p>
                        <p>个人分配: 表示仅账单关联的用户进行分配</p>
                      </div>
                    }
                    request={async () => [
                      { label: '全员分配', value: 1 },
                      { label: '按客户归属分配', value: 2 },
                    ]}
                    placeholder="请选择锚定指标"
                    rules={[{ required: true, message: '请选择锚定指标' }]}
                  />
                </Col>
              ) : null;
            }}
          </ProFormDependency>
        </Row>
        <ProFormDependency name={['type', 'deptIds']}>
          {({ type, deptIds }) => {
            switch (type) {
              case 1:
                // 人员分配规则
                return (
                  <ProCard
                    title="参与分配人员及比例"
                    extra={
                      <ProFormGroup>
                        <Button
                          type="dashed"
                          onClick={() => {
                            if (!currentDeptIds || currentDeptIds.length === 0) {
                              message.error('请先选择部门');
                              return;
                            }
                            addAllocationRuleList(employeeList, currentDeptIds);
                          }}
                        >
                          新增分配规则
                        </Button>
                      </ProFormGroup>
                    }
                    // ghost
                  >
                    <ProCard title="" colSpan={3} ghost style={{ marginRight: 10 }}>
                      <ProCard bordered headerBordered size="small" headStyle={{ paddingBlock: 47 }} title={<div>员工姓名</div>} bodyStyle={{ paddingBlock: 0, paddingInline: 0 }}>
                        <ProCard
                          // title="姓名"
                          headerBordered
                          ghost
                          bodyStyle={{
                            paddingTop: 13,
                            paddingBottom: 0,
                          }}
                        >
                          {employeeList.map((n) => (
                            <div
                              key={n.ssoUserId}
                              style={{
                                height: 24,
                                lineHeight: '24px',
                                paddingBottom: 24,
                              }}
                            >
                              {n.name}
                            </div>
                          ))}
                        </ProCard>
                        {/* <div
                          style={{
                            // paddingTop: 24,
                            height: 30,
                            verticalAlign: 'center',
                            lineHeight: '30px',
                            paddingBottom: 26,
                            borderBottom: '1px solid #ccc',
                          }}
                        >
                          姓名
                        </div> */}
                      </ProCard>
                    </ProCard>
                    <ProCard bodyStyle={{ overflow: 'scroll', paddingBlock: 0, paddingInline: 0 }} colSpan={21}>
                      <Form size="small" form={allocationRuleListForm} name="allocation_rule_form" autoComplete="off">
                        <Form.List name="ruleList">
                          {(fields, { add, remove }) => (
                            <div style={{ display: 'flex', marginLeft: 16 }}>
                              {fields.map((field, index) => {
                                return (
                                  <Card
                                    headStyle={{ display: 'flex', paddingBlock: 0 }}
                                    bodyStyle={{
                                      paddingBlock: 0,
                                      paddingTop: 14,
                                    }}
                                    title={
                                      <>
                                        <Form.Item
                                          name={[field.name, 'name']}
                                          style={{
                                            marginTop: 20,
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message: '请输入规则标题',
                                            },
                                          ]}
                                        >
                                          <Input size="small" placeholder="请输入规则标题" allowClear />
                                        </Form.Item>
                                        <div style={{ display: 'flex', gap: 16 }}>
                                          <div style={{ flex: 1, alignSelf: 'center' }}>
                                            <Form.Item
                                              // noStyle
                                              name={[field.name, 'startingMonth']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: '请输入起始月',
                                                },
                                              ]}
                                            >
                                              <InputNumber placeholder="起始月" min={1} />
                                            </Form.Item>
                                          </div>
                                          <div style={{ flex: 1 }}>
                                            <Form.Item
                                              // noStyle
                                              name={[field.name, 'endingMonth']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: '请输入结束月',
                                                },
                                              ]}
                                            >
                                              <InputNumber placeholder="结束月" min={1} />
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </>
                                    }
                                    key={`${field.name + 1}`}
                                    extra={
                                      <CloseOutlined
                                        style={{
                                          position: 'absolute',
                                          top: 3,
                                          right: 3,
                                        }}
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    }
                                    style={{
                                      marginRight: 10,
                                    }}
                                  >
                                    <Form.Item name={[field.name, 'id']} hidden>
                                      <Input />
                                    </Form.Item>
                                    <Form.Item name={[field.name, 'allocationPlanId']} hidden>
                                      <Input />
                                    </Form.Item>

                                    <Form.List name={[field.name, 'employeeList']} key={field.name + 1}>
                                      {(subFields, subOpt) => (
                                        <div style={{ display: 'flex', flexDirection: 'column' }} className="rule-item">
                                          {subFields.map((subField) => {
                                            const _subField = allocationRuleListForm.getFieldsValue()['ruleList'][field.name]['employeeList'][subField.key];
                                            console.log('subField.key', subField.key);
                                            return (
                                              <div key={subField.key}>
                                                <Form.Item name={[subField.name, 'ratio']}>
                                                  <InputNumber min={0} max={100} placeholder={_subField['employeeName'] + '比例'} addonAfter="%" />
                                                </Form.Item>
                                                <Form.Item name={[subField.name, 'employeeId']} hidden>
                                                  <Input value={_subField['employeeId']} />
                                                </Form.Item>
                                                <Form.Item name={[subField.name, 'id']} hidden>
                                                  <Input value={_subField['id']} />
                                                </Form.Item>
                                                <Form.Item name={[subField.name, 'allocationPlanId']} hidden>
                                                  <Input value={_subField['allocationPlanId']} />
                                                </Form.Item>
                                                <Form.Item name={[subField.name, 'allocationPlanRuleId']} hidden>
                                                  <Input value={_subField['allocationPlanRuleId']} />
                                                </Form.Item>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </Form.List>
                                  </Card>
                                );
                              })}
                            </div>
                          )}
                        </Form.List>
                      </Form>
                    </ProCard>
                  </ProCard>
                );
              case 2:
                return (
                  <ProCard
                    title="参与分配部门及比例"
                    extra={
                      <ProFormGroup>
                        <Button
                          type="dashed"
                          onClick={() => {
                            if (!currentDeptIds || currentDeptIds.length === 0) {
                              message.error('请先选择部门');
                              return;
                            }
                            addAllocationRuleList(employeeList, currentDeptIds);
                          }}
                        >
                          新增分配规则
                        </Button>
                      </ProFormGroup>
                    }
                    ghost
                  >
                    <ProCard title="" colSpan={3} ghost style={{ marginRight: 10 }}>
                      <ProCard bordered headerBordered size="small" headStyle={{ paddingBlock: 47 }} title={<div>部门名称</div>} bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ProCard
                          headerBordered
                          ghost
                          bodyStyle={{
                            paddingTop: 14,
                            paddingBottom: 25,
                          }}
                        >
                          {currentDept?.name}
                        </ProCard>
                      </ProCard>
                    </ProCard>
                    <ProCard bodyStyle={{ overflow: 'scroll', paddingBlock: 0, paddingInline: 0 }} colSpan={21}>
                      <Form size="small" form={allocationRuleListForm} name="allocation_rule_form" autoComplete="off">
                        <Form.List name="ruleList">
                          {(fields, { add, remove }) => (
                            <div style={{ display: 'flex', marginLeft: 16 }}>
                              {fields.map((field, index) => {
                                return (
                                  <Card
                                    headStyle={{ display: 'flex', paddingBlock: 0 }}
                                    bodyStyle={{
                                      paddingBlock: 0,
                                      paddingTop: 14,
                                    }}
                                    size="small"
                                    title={
                                      <>
                                        <Form.Item
                                          name={[field.name, 'name']}
                                          style={{
                                            marginTop: 20,
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message: '请输入规则标题',
                                            },
                                          ]}
                                        >
                                          <Input placeholder="请输入规则标题" />
                                        </Form.Item>
                                        <div style={{ display: 'flex', gap: 16 }}>
                                          <div style={{ flex: 1, alignSelf: 'center' }}>
                                            <Form.Item
                                              name={[field.name, 'startingMonth']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: '请输入起始月',
                                                },
                                              ]}
                                            >
                                              <InputNumber placeholder="起始月" min={1} />
                                            </Form.Item>
                                          </div>
                                          <div style={{ flex: 1 }}>
                                            <Form.Item
                                              name={[field.name, 'endingMonth']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: '请输入结束月',
                                                },
                                              ]}
                                            >
                                              <InputNumber placeholder="结束月" min={1} />
                                            </Form.Item>
                                          </div>
                                          <Form.Item name={[field.name, 'id']} hidden>
                                            <Input />
                                          </Form.Item>
                                          <Form.Item name={[field.name, 'allocationPlanId']} hidden>
                                            <Input />
                                          </Form.Item>
                                        </div>
                                      </>
                                    }
                                    key={`${field.name + 1}`}
                                    extra={
                                      <CloseOutlined
                                        style={{
                                          position: 'absolute',
                                          top: 3,
                                          right: 3,
                                        }}
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    }
                                    style={{
                                      marginRight: 10,
                                    }}
                                  >
                                    <div>
                                      <Form.Item name={[field.name, 'deptRatio']}>
                                        <InputNumber min={0} max={100} placeholder={'部门' + '比例'} addonAfter="%" />
                                      </Form.Item>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                          )}
                        </Form.List>
                      </Form>
                    </ProCard>
                  </ProCard>
                );
              default:
                return null;
            }
          }}
        </ProFormDependency>
      </ModalForm>
    </PageContainer>
  );
}

export default AllocationPlanList;
