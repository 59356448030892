import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ActionType, ModalForm, PageContainer, ProCard, ProColumns, ProFormInstance, ProFormText, ProTable } from '@ant-design/pro-components';
import { Cascader, message, Space, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  EmployeeApiDto,
  getDepartmentList,
  getEmployeeList,
  getProductEmployee,
  getProductList,
  ProductApiDto,
  ProductSettingDto,
  saveProductEmployee,
  UpdateProductSettingDto,
} from '../../api/service';
import { listToTree } from '../../utils/common';

interface EmployeeDataSourceType extends EmployeeApiDto {
  inWhitelist: boolean;
  inBlacklist: boolean;
  disabledWithlist: boolean;
  disabledBlacklist: boolean;
}

function Product() {
  const [visible, setVisible] = useState<boolean>(false);
  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);
  const [currentProduct, setCurrentProduct] = useState<ProductApiDto>();
  const [productEmployee, setProductEmployee] = useState<ProductSettingDto>();
  const [employeeDataSource, setEmployeeDataSource] = useState<EmployeeDataSourceType[]>([]);
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const employeeActionRef = useRef<ActionType>();
  const employeeFormRef = useRef<ProFormInstance>();
  const updateProductEmployeeFormRef = useRef<ProFormInstance>();

  async function doGetProductEmployee(productId: number) {
    const result = await getProductEmployee(productId);
    if (result?.success) {
      const data = result.data;
      setProductEmployee(data);

      updateProductEmployeeFormRef.current?.setFieldsValue({
        id: data?.id,
        productId: data?.productId,
      });
    }
  }

  async function doGetEmployeeList() {
    const result = await getEmployeeList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        const data = result.data;
        const formatData: EmployeeDataSourceType[] = [];
        data.forEach((item: any) => {
          let _data = { ...item };
          _data.inBlacklist = (productEmployee?.employeeBlacklist && productEmployee?.employeeBlacklist.split(',').includes(item.userCode)) || false;
          _data.inWhitelist = (productEmployee?.employeeWhitelist && productEmployee?.employeeWhitelist.split(',').includes(item.userCode)) || false;
          _data.disabledWithlist = _data.inBlacklist;
          _data.disabledBlacklist = _data.inWhitelist;
          formatData.push(_data);
        });
        console.log('formatData', formatData);

        setEmployeeDataSource(formatData);
      }
    }
  }

  async function getDepartmentTreeData() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        const data = result.data;
        // setDepartmentList(data);
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
        doGetEmployeeList();
      }
    }
  }

  useEffect(() => {
    getDepartmentTreeData();
  }, []);

  useEffect(() => {
    employeeActionRef.current?.reload();
  }, [employeeDataSource]);

  useEffect(() => {
    doGetEmployeeList();
  }, [productEmployee]);

  useEffect(() => {
    if (visible) {
      doGetProductEmployee(currentProduct?.productId as number);
    } else {
      setProductEmployee(undefined);
      setEmployeeDataSource([]);
    }
  }, [visible]);

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: EmployeeApiDto[]) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //   },
  //   getCheckboxProps: (record: EmployeeApiDto) => ({
  //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  const columns: ProColumns[] = [
    {
      title: '产品编码',
      dataIndex: 'productCode',
      hideInSearch: true,
    },
    {
      title: '产品名称',
      dataIndex: 'productName',
      // formItemProps: {
      //   lightProps: {
      //     labelFormatter: (value) => `${value}`,
      //   },
      // },
    },
    {
      title: '产品线名称',
      dataIndex: 'productLineName',
    },
    {
      title: '商品编号',
      dataIndex: 'goodsId',
      hideInSearch: true,
    },
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      // hideInSearch: true,
    },
    // {
    //   title: '商品分类名称',
    //   dataIndex: 'goodsClassifyName',
    //   hideInSearch: true,
    // },
    {
      title: '采购成本',
      dataIndex: 'goodsCost',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '绑定产品激励',
      dataIndex: 'allocationPlanGroup',
      hideInSearch: true,
      render: (text: any, record: any) => {
        let name = '-';
        if (record.allocationPlanGroupList && record.allocationPlanGroupList.length > 0) {
          name = record.allocationPlanGroupList.map((n: { name: any }) => n.name).join(' / ');
        }
        return name;
      },
      ellipsis: true,
    },
    // {
    //   title: '分配设置',
    //   dataIndex: 'allocated',
    //   hideInSearch: true,
    //   render: (_, { allocated }) => (!!allocated ? <Tag color="green">已配置</Tag> : <Tag color="volcano">未配置</Tag>),
    // },
    // {
    //   title: '操作',
    //   key: 'option',
    //   align: 'center',
    //   valueType: 'option',
    //   render: (text: any, record: any) => {
    //     return (
    //       <Button
    //         type="link"
    //         onClick={() => {
    //           setCurrentProduct(record);
    //           setVisible(true);
    //         }}
    //       >
    //         人员设置
    //       </Button>
    //     );
    //   },
    // },
  ];

  const onChangeEmployeeList = (record: EmployeeDataSourceType, field: string) => {
    if (field === 'inWhitelist') {
      const _data = employeeDataSource.map((item) => {
        if (item.userCode === record.userCode) {
          item.inWhitelist = !item.inWhitelist;
          item.disabledBlacklist = item.inWhitelist;
        }
        return item;
      });
      setEmployeeDataSource(_data);
    } else if (field === 'inBlacklist') {
      const _data = employeeDataSource.map((item) => {
        if (item.userCode === record.userCode) {
          item.inBlacklist = !item.inBlacklist;
          item.disabledWithlist = item.inBlacklist;
        }
        return item;
      });
      setEmployeeDataSource(_data);
    }
  };

  const employeeColumns: ProColumns<EmployeeDataSourceType>[] = [
    {
      title: '工号',
      key: 'userCode',
      dataIndex: 'userCode',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '所属部门',
      dataIndex: 'ssoDeptId',
      renderFormItem: (item) => {
        return (
          <Cascader
            variant="outlined"
            options={departmentTreeData}
            onChange={(value) => {
              console.log('value', value);
              employeeFormRef.current?.setFieldValue('ssoDeptId', value);
            }}
            fieldNames={{ label: 'name', value: 'id' }}
            placeholder="请选择"
          />
        );
      },
      render: (value, record) => {
        return <>{record.deptName || '-'}</>;
      },
    },
    {
      title: '是否在白名单',
      dataIndex: 'inWhitelist',
      valueType: 'switch',
      width: 200,
      render: (value, record) => {
        return (
          <Switch
            disabled={record.disabledWithlist}
            onChange={(value) => onChangeEmployeeList(record, 'inWhitelist')}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.inWhitelist}
          />
        );
      },
    },
    {
      title: '是否在黑名单',
      dataIndex: 'inBlacklist',
      valueType: 'switch',
      width: 200,
      render: (value, record) => {
        return (
          <Switch
            disabled={record.disabledBlacklist}
            onChange={(value) => onChangeEmployeeList(record, 'inBlacklist')}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.inBlacklist}
          />
        );
      },
    },
  ];

  return (
    <PageContainer title="产品列表">
      <ProTable
        tableStyle={{
          padding: 24,
        }}
        actionRef={actionRef}
        formRef={formRef}
        rowKey={(record) => record.productId + ''}
        columns={columns}
        toolBarRender={false}
        request={async (params, sorter): Promise<any> => {
          const result = await getProductList();
          if (result?.success) {
            let list = result.data || [];
            console.log('params', params);

            list = list.filter((item) => {
              // 值来自table form
              let passProductName = true;
              let passProductLineName = true;
              let passGoodsName = true;
              // 判断是否有 userCode 条件，如果有则进行判断
              if (params.productName) {
                passProductName = item.productName?.includes(params.productName) || false;
              }

              // 判断是否有 name 条件，如果有则进行判断
              if (params.productLineName) {
                passProductLineName = item.productLineName?.includes(params.productLineName) || false;
              }

              if (params.goodsName) {
                passGoodsName = params.goodsName.includes(item.goodsName);
              }
              // 通过所有条件的项将被保留
              return passProductName && passProductLineName && passGoodsName;
            });
            return {
              data: list,
              success: true,
            };
          }
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
      <ModalForm<UpdateProductSettingDto>
        title="设置产品人员名单"
        open={visible}
        autoFocusFirstInput
        modalProps={{
          onCancel: () => setVisible(false),
        }}
        width={'80%'}
        formRef={updateProductEmployeeFormRef}
        submitTimeout={2000}
        onFinish={async (values) => {
          console.log('values', values);
          // 从 employeeDatasource 中获取 选中的值
          const _employeeWhitelist = employeeDataSource
            .filter((n) => n.inWhitelist)
            .map((n) => n.userCode)
            .join(',');
          const _employeeBlacklist = employeeDataSource
            .filter((n) => n.inBlacklist)
            .map((n) => n.userCode)
            .join(',');
          const params = {
            ...values,
            employeeWhitelist: _employeeWhitelist,
            employeeBlacklist: _employeeBlacklist,
          };
          console.log('params', params);
          const result = await saveProductEmployee(params);
          if (result?.success) {
            message.success('操作成功');
            setVisible(false);
            return true;
          } else {
            return false;
          }
        }}
      >
        <ProFormText name="id" hidden />
        <ProFormText name="productId" hidden />
        {/* <ProFormText name="employeeWhitelist" hidden />
        <ProFormText name="employeeBlacklist" hidden /> */}
        <ProCard title="" split="vertical" gutter={100}>
          {/* <ProCard title="产品人员白名单" bordered>
            <ProTable search={false} toolbar={{}} toolBarRender={false}></ProTable>
          </ProCard> */}
          <ProCard bordered>
            <ProTable
              actionRef={employeeActionRef}
              formRef={employeeFormRef}
              rowKey={'ssoUserId'}
              columns={employeeColumns}
              toolbar={{
                subTitle: (
                  <Space>
                    {/* <>白名单1人</>
                    <>黑名单1人</> */}
                  </Space>
                ),
                multipleLine: false,
                settings: [],
              }}
              // rowSelection={rowSelection}
              search={{
                filterType: 'light',
              }}
              request={async (params: any, sorter, filter): Promise<any> => {
                console.log('查询', params, sorter, filter);
                const datasouce = employeeDataSource;
                let data: EmployeeApiDto[] = datasouce.filter((item) => {
                  // 默认通过所有条件
                  let passUserCode = true;
                  let passName = true;
                  // 值来自table form
                  let passDeptId = true;
                  let passInWhitelist = true;
                  let passInBlacklist = true;
                  // 判断是否有 userCode 条件，如果有则进行判断
                  if (params.userCode) {
                    passUserCode = item.userCode?.includes(params.userCode);
                  }
                  // 判断是否有 name 条件，如果有则进行判断
                  if (params.name) {
                    passName = item.name?.includes(params.name);
                  }
                  // 判断是否有 ssoDeptId 条件，如果有则进行判断
                  if (params.ssoDeptId && params.ssoDeptId.length > 0) {
                    passDeptId = params.ssoDeptId.includes(item.ssoDeptId);
                  }
                  if (params.inWhitelist) {
                    passInWhitelist = item.inWhitelist === params.inWhitelist;
                  }
                  if (params.inBlacklist) {
                    passInWhitelist = item.inBlacklist === params.inBlacklist;
                  }
                  // 通过所有条件的项将被保留
                  return passUserCode && passName && passDeptId && passInWhitelist && passInBlacklist;
                });
                return {
                  data: data,
                  success: true,
                };
              }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                hideOnSinglePage: false,
              }}
            />
          </ProCard>
        </ProCard>
      </ModalForm>
    </PageContainer>
  );
}

export default Product;
