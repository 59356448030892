import { ConfigProvider, theme } from 'antd';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutWrapper from './layout';
import Dashboard from './pages/dashboard/Dashboard';
import { RootState } from './state/store';
function App() {
  const themeStyle = useSelector((state: RootState) => state.app.theme);
  const darkMode = useSelector((state: RootState) => state.app.darkMode);
  console.log('darkMode', darkMode);

  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: themeStyle.colorPrimary,
          colorBgBase: themeStyle.backgroundColor,
        },
        components: {
          Form: {},
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/*" element={<LayoutWrapper></LayoutWrapper>}></Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
