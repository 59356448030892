import { AppstoreOutlined, CalendarOutlined } from '@ant-design/icons';

import AllocationGroup from '../pages/allocation/plan/GroupList';
import AllocationList from '../pages/allocation/plan/List';
import Bill from '../pages/allocation/record/Bill';
import BillEmployee from '../pages/allocation/record/BillEmployee';
import CostList from '../pages/cost/List';
import Customer from '../pages/customer/List';
import PersonalCustomer from '../pages/customer/PersonalList';
import Employee from '../pages/employee/List';
import Home from '../pages/home/Index';
import GrantingList from '../pages/incentive/GrantingList';
import ProductList from '../pages/product/List';
import SettlementList from '../pages/settlement/List';
import RoleList from '../pages/system/RoleList';

export const routes = [
  {
    path: '/',
    key: '/',
    label: '首页',
    element: <Home />,
    icon: <AppstoreOutlined />,
  },
  {
    path: '/employee',
    key: '/employee',
    label: '人员管理',
    icon: <CalendarOutlined />,
    access: 'ADMIN',
    children: [
      {
        path: '/employee/list',
        key: '/employee/list',
        label: '人员列表',
        element: <Employee />,
        access: 'ADMIN',
      },
    ],
  },
  {
    path: '/customer',
    key: '/customer',
    label: '客户管理',
    icon: <CalendarOutlined />,
    children: [
      {
        path: '/customer/list',
        key: '/customer/list',
        label: '客户列表',
        element: <Customer />,
      },
      {
        path: '/customer/list/personal',
        key: '/customer/list/personal',
        label: '我的客户',
        element: <PersonalCustomer />,
      },
    ],
  },
  {
    path: '/product',
    key: '/product',
    label: '产品管理',
    icon: <CalendarOutlined />,
    access: 'ADMIN',
    children: [
      {
        path: '/product/list',
        key: '/product/list',
        label: '产品列表',
        element: <ProductList />,
        access: 'ADMIN',
      },
    ],
  },
  {
    path: '/allocation',
    key: '/allocation',
    label: '激励方案',
    icon: <CalendarOutlined />,
    access: 'ADMIN',
    children: [
      {
        path: '/allocation/list',
        key: '/allocation/list',
        label: '方案设置',
        element: <AllocationList />,
        access: 'ADMIN',
      },
      {
        path: '/allocation/group/list',
        key: '/allocation/group/list',
        label: '产品激励',
        element: <AllocationGroup />,
        access: 'ADMIN',
      },
    ],
  },
  {
    path: '/cost',
    key: '/cost',
    label: '部门成本',
    icon: <CalendarOutlined />,
    access: 'ADMIN',
    children: [
      {
        path: '/cost/input',
        key: '/cost/input',
        label: '成本录入',
        element: <CostList />,
        access: 'ADMIN',
      },
    ],
  },
  {
    path: '/allocation/manage',
    key: '/allocation_manage',
    label: '激励管理',
    icon: <CalendarOutlined />,
    children: [
      {
        path: '/allocation/granting',
        key: '/allocation/granting',
        label: '激励发放',
        element: <GrantingList />,
        access: 'ADMIN',
      },
      {
        path: '/allocation/bill/record',
        key: '/allocation/bill/record',
        label: '激励账单',
        element: <Bill />,
      },
      {
        path: '/allocation/employee/record',
        key: '/allocation/employee/record',
        label: '激励明细',
        element: <BillEmployee />,
      },
    ],
  },
  {
    path: '/settlement/record',
    key: '/settlement/record',
    label: '结算明细',
    element: <SettlementList />,
    icon: <CalendarOutlined />,
  },
  {
    path: '/system/setting',
    key: '/system/setting',
    label: '系统设置', // 管理员设置
    element: <RoleList />,
    icon: <CalendarOutlined />,
    access: 'ADMIN',
  },
];
