import { SearchOutlined } from '@ant-design/icons';
import { ActionType, ModalForm, PageContainer, ProCard, ProColumns, ProFormCascader, ProFormInstance, ProFormText, ProTable } from '@ant-design/pro-components';
import { injectGlobal } from '@emotion/css';
import { Button, Col, GetProp, Input, message, Popconfirm, Row, Table, TableColumnsType, TableProps, Tooltip, Transfer, TransferProps } from 'antd';
import { debounce } from 'lodash';
import difference from 'lodash/difference';
import { useEffect, useRef, useState } from 'react';
import {
  AllocationPlanDto,
  AllocationPlanGroupDto,
  createAllocationPlanGroup,
  deleteAllocationPlanGroup,
  DepartmentApiDto,
  getAllocationPlanGroupList,
  getAllocationPlanGroupPage,
  getAllocationPlanList,
  getDepartmentList,
  getProductList,
  ProductApiDto,
  updateAllocationPlanGroup,
  UpdateAllocationPlanGroupDto,
} from '../../../api/service';
import { formatProTableQueryData, formatProTableReulstData } from '../../../utils/ant-util';
import { findChildIds, listToTree } from '../../../utils/common';

injectGlobal`
  .ant-form-vertical .ant-form-item-row {
    flex-direction: inherit !important;
  }
`;

enum Action {
  ADD = '新建产品激励',
  EDIT = '编辑产品激励',
}

type TransferItem = GetProp<TransferProps, 'dataSource'>[number];
type TableRowSelection<T extends object> = TableProps<T>['rowSelection'];

interface AllocationPlanDatasourceType extends AllocationPlanDto {
  key: string;
  disabled: boolean;
}

interface ProductDatasourceType extends ProductApiDto {
  key: string;
  checked: boolean;
  disabled: boolean;
}

function AllocationPlanGroupList() {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const modalFormRef = useRef<ProFormInstance>();
  const [visible, setVisible] = useState<boolean>(false);
  // 当前操作
  const [currentAction, setCurrentAction] = useState<Action>();
  const [productDatasource, setProductDatasource] = useState<ProductDatasourceType[]>([]);
  const [productDatasourceFilter, setProductDatasourceFilter] = useState<ProductDatasourceType[]>([]);
  const [allocationPlanDatasource, setAllocationPlanDatasource] = useState<AllocationPlanDatasourceType[]>([]);
  const [allocationPlanDatasourceBackup, setAllocationPlanDatasourceBackup] = useState<AllocationPlanDatasourceType[]>([]);
  const [departmentList, setDepartmentList] = useState<DepartmentApiDto[]>([]);
  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);
  const [productKeys, setProductKeys] = useState<string[]>();
  const [targetKeys, setTargetKeys] = useState<string[]>();
  const [currentAllocationPlanGroup, setCurrentAllocationPlanGroup] = useState<AllocationPlanGroupDto>();
  const [productDisabledKeys, setProductDisabledKeys] = useState<string[]>();
  const [currentDeptId, setCurrentDeptId] = useState<number>();

  const [allocationPlanGroupList, setAllocationPlanGroupList] = useState<AllocationPlanGroupDto[]>();
  interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: AllocationPlanDatasourceType[];
    leftColumns: TableColumnsType<AllocationPlanDatasourceType>;
    rightColumns: TableColumnsType<AllocationPlanDatasourceType>;
  }

  // Customize Table Transfer
  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
    <Transfer {...restProps}>
      {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection: TableRowSelection<TransferItem> = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
            const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys as string[], selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : undefined, height: '500px' }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );

  const leftTableColumns: TableColumnsType<AllocationPlanDatasourceType> = [
    {
      dataIndex: 'name',
      title: '方案名称',
      // hidden: true,
    },
    {
      title: '方案适用类型',
      dataIndex: 'type',
      render: (value, record) => {
        return <>{value === 1 ? '个人' : '部门'}</>;
      },
    },
    {
      title: '适用部门',
      dataIndex: 'deptId',
      render: (value, record) => {
        const dept = departmentList.find((n) => n.id === record.deptId);
        return <>{(dept && dept.name) || '-'}</>;
      },
    },
  ];

  const rightTableColumns: TableColumnsType<AllocationPlanDatasourceType> = [
    {
      dataIndex: 'name',
      title: '方案名称',
      // hidden: true,
    },
    {
      title: '方案适用类型',
      dataIndex: 'type',
      render: (value, record) => {
        return <>{value === 1 ? '个人' : '部门'}</>;
      },
    },
    {
      title: '适用部门',
      dataIndex: 'deptId',
      render: (value, record) => {
        const dept = departmentList.find((n) => n.id === record.deptId);
        return <>{(dept && dept.name) || '-'}</>;
      },
    },
  ];

  const productColmns: TableColumnsType<ProductDatasourceType> = [
    {
      dataIndex: 'productCode',
      title: '产品编号',
      ellipsis: true,
      width: 140,
    },
    {
      dataIndex: 'productName',
      title: '产品名称',
      ellipsis: true,
    },
    {
      dataIndex: 'productLineName',
      title: '产品线',
      ellipsis: true,
    },
  ];

  const onChangeTree = (nextTargetKeys: string[]) => {
    console.log('nextTargetKeys', nextTargetKeys);
    if (!currentDeptId) {
      message.error('请先选择部门');
      return;
    }
    // 检查产品激励中是否存在两个部门方案
    let deptList = allocationPlanDatasourceBackup.filter((n) => nextTargetKeys.includes(n.id + ''));
    if (deptList) {
      deptList = deptList.filter((n) => n.type === 2);
      if (deptList && deptList.length > 1) {
        message.error('产品激励中不能包含多个部门方案');
        return;
      }
    }
    setTargetKeys(nextTargetKeys);
    // 将选取的方案编号 设置到form中
    modalFormRef.current?.setFieldValue('allocationPlanIds', nextTargetKeys.join(','));
  };

  async function doGetDepartmentList() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        let data = result.data;
        data = data.filter((n) => n.type === 1);
        setDepartmentList(data);

        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
      }
    }
  }

  // 获取所有产品激励
  const doGetAllocationPlanGroupList = async () => {
    const result = await getAllocationPlanGroupList({});
    if (result?.success) {
      console.log('result.data', result.data);
      if (result.data && result.data.length > 0) {
        const other = result.data.filter((n) => n.id !== currentAllocationPlanGroup?.id);
        // 获取所有已经绑定的产品编号
        let _productDisabledKeys: any[] = [];
        other.forEach((n) => {
          const _ids: string[] = n.productIds?.split(',') as string[];
          _productDisabledKeys = [..._productDisabledKeys, ..._ids];
        });
        console.log('_productDisabledKeys', _productDisabledKeys);
        // setProductDisabledKeys(_productDisabledKeys);
      }
      setAllocationPlanGroupList(result.data);
    }
  };

  async function doGetProductList() {
    const result = await getProductList();
    let list = [];
    let data = result.data;
    if (result?.success && data && data.length > 0) {
      list = data.map((n) => {
        return {
          ...n,
          key: n.productId + '',
          disabled: false,
          checked: false,
        };
      });
      setProductDatasourceFilter([...list]);
      setProductDatasource(list);
      // 设置选中的方案
    }
  }
  async function doGetAllocationPlanList() {
    const result = await getAllocationPlanList({});
    let list: AllocationPlanDatasourceType[] = [];
    let data = result.data;
    if (result?.success && data && data.length > 0) {
      list = data.map((n) => {
        return {
          ...n,
          key: n.id + '',
          disabled: false,
        };
      });
      setAllocationPlanDatasource(list);
      setAllocationPlanDatasourceBackup(list);
      // 设置选中的方案
    }
  }

  const doDelete = async (id: number) => {
    const result = await deleteAllocationPlanGroup(id);
    if (result?.success) {
      message.success('删除成功');
      actionRef.current?.reload();
    }
  };

  const onChangeProductNameSearch = debounce((name) => {
    // 在这里执行搜索操作，例如调用后端API或更新组件状态
    const _productDatasource = productDatasource.filter((n) => n.productName.includes(name));
    setProductDatasourceFilter(_productDatasource);
  }, 500);

  useEffect(() => {
    if (visible) {
      doGetProductList();
      doGetAllocationPlanList();
      doGetDepartmentList();
      if (Action.EDIT === currentAction) {
        doGetAllocationPlanGroupList();
      }
    } else {
      modalFormRef.current?.resetFields();
      setProductKeys([]);
      setTargetKeys([]);
    }
  }, [visible]);

  const toAdd = () => {
    setVisible(true);
    setCurrentAction(Action.ADD);
    formRef?.current?.resetFields();
  };

  const toEdit = (record: AllocationPlanGroupDto) => {
    setCurrentAction(Action.EDIT);
    setCurrentAllocationPlanGroup(record);
    let _ids = (record.allocationPlanIds && record.allocationPlanIds.split(',')) || [];
    console.log('_ids', _ids);
    setTargetKeys(_ids);
    let _productIds = (record.productIds && record.productIds.split(',')) || [];
    console.log(' _productIds', _productIds);
    setTimeout(() => {
      modalFormRef?.current?.setFieldsValue({
        ...record,
      });
    });
    setProductKeys(_productIds);
    setVisible(true);
  };

  const columns: ProColumns<AllocationPlanGroupDto>[] = [
    {
      title: '激励名称',
      dataIndex: 'name',
    },
    {
      title: '关联方案数',
      dataIndex: 'customerList',
      align: 'center',
      render: (value, record) => {
        const title = <div>{record.allocationPlanList && record.allocationPlanList.map((n) => <p key={n + ''}>{n.name}</p>)}</div>;
        return (
          <Tooltip placement="top" title={title}>
            <a>{record.allocationPlanList ? record.allocationPlanList.length : 0}</a>
          </Tooltip>
        );
      },
      hideInSearch: true,
    },
    {
      title: '关联产品数',
      dataIndex: 'productList',
      align: 'center',
      render: (value, record) => {
        const title = <div>{record.productList && record.productList.map((n) => <p key={n + ''}>{n.productName}</p>)}</div>;
        return (
          <Tooltip placement="top" title={title}>
            <a>{record.productList ? record.productList.length : 0}</a>
          </Tooltip>
        );
      },
      hideInSearch: true,
    },
    {
      title: '编辑人',
      dataIndex: 'updatedByName',
      align: 'center',
      hideInSearch: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      align: 'center',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '操作',
      valueType: 'option',
      render: (_, record) => [
        <a type="link" key="edit" onClick={() => toEdit(record)}>
          编辑
        </a>,
        <Popconfirm key="delete" title="删除后关联的产品方案将失效, 确定删除吗？" onConfirm={() => doDelete(record.id as number)}>
          <a>删除</a>
        </Popconfirm>,
      ],
    },
  ];

  return (
    <PageContainer title="产品激励列表">
      <ProTable
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'id'}
        columns={columns}
        toolBarRender={() => [
          <Button key="primary" type="primary" onClick={toAdd}>
            新增产品激励
          </Button>,
        ]}
        toolbar={{ title: '', settings: [] }}
        request={async (params, sorter): Promise<any> => {
          const result = await getAllocationPlanGroupPage(formatProTableQueryData(params));
          return formatProTableReulstData(result);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
      <ModalForm<UpdateAllocationPlanGroupDto>
        title={currentAction}
        open={visible}
        autoFocusFirstInput
        modalProps={{
          onCancel: () => setVisible(false),
        }}
        width={'80%'}
        formRef={modalFormRef}
        submitTimeout={2000}
        onFinish={async (values) => {
          console.log('values', values);
          let result: any = {};
          if (!values.allocationPlanIds) {
            message.error('请至少选择一个方案');
            return false;
          }
          if (!values.productIds) {
            message.error('请至少选择一个产品');
            return false;
          }
          if (currentAction === Action.ADD) {
            result = await createAllocationPlanGroup(values);
          } else {
            result = await updateAllocationPlanGroup(values);
          }
          if (result?.success) {
            message.success('操作成功');
            setVisible(false);
            actionRef.current?.reload();
          }
          return true;
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <ProFormText name="name" label="产品激励名称" placeholder="产品激励名称" rules={[{ required: true, message: '请输入产品激励名称' }, { max: 50 }]} />
          </Col>
          <Col span={12}>
            <ProFormCascader
              // name="deptId"
              label="适用部门"
              placeholder="请选择部门"
              allowClear
              fieldProps={{
                changeOnSelect: true,
                options: departmentTreeData,
                fieldNames: { label: 'name', value: 'id' },
                onChange: (value: any, options: any) => {
                  console.log('value', value, options);
                  let _value = (value && value[value.length - 1]) || 0;
                  let _name = (options && options.map((n: { name: any }) => n.name).join('/')) || '';
                  setCurrentDeptId(_value);
                  // 值变动后进行保存到form
                  modalFormRef.current?.setFieldValue('deptId', _value);
                  modalFormRef.current?.setFieldValue('deptName', _name);
                  // 过滤出当前部门及子部门下所有方案
                  const _childDeptList = findChildIds(departmentList, _value);
                  const _childDeptIds = _childDeptList.map((n) => n.id);
                  // console.log('_childDeptIds', _childDeptIds);
                  let _allDeptIds = [_value].concat(_childDeptIds);
                  const _filter = allocationPlanDatasourceBackup.filter((n) => (_value !== 0 ? _allDeptIds.includes(n.deptId) : true));
                  setAllocationPlanDatasource(_filter);
                },
                onClear() {
                  setCurrentDeptId(0);
                },
              }}
              rules={[{ required: true, message: '请选择部门' }]}
            />
          </Col>
        </Row>
        <ProFormText name="id" label="产品激励编号" hidden />
        <ProFormText name="productIds" label="产品激励关联的产品" hidden />
        <ProFormText name="allocationPlanIds" label="产品激励关联的方案" hidden />
        <ProFormText name="deptId" label="部门编号" hidden />
        <ProFormText name="deptName" label="部门名称" hidden />
        <div
          style={{
            display: 'flex',
            gap: 16,
          }}
        >
          <ProCard title="" bodyStyle={{ paddingBlock: 0, paddingInline: 0 }} style={{ flex: 1 }}>
            <TableTransfer
              titles={['备选方案', '已选方案']}
              rowKey={(record: AllocationPlanDatasourceType) => record.key}
              dataSource={allocationPlanDatasource}
              targetKeys={targetKeys}
              showSearch={true}
              onChange={onChangeTree}
              filterOption={(inputValue, item) => {
                return item.name!.indexOf(inputValue) !== -1;
              }}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
            />
          </ProCard>
          <ProCard title="" bodyStyle={{ paddingBlock: 0, paddingInline: 0 }} style={{ width: '30%', border: '1px solid #d9d9d9', borderRadius: 8 }}>
            <div style={{ padding: '8px 12px 9px', textAlign: 'right', border: '1px solid rgba(5, 5, 5, 0.06)' }}>已关联的产品 {productKeys?.length || 0} 项</div>
            <div style={{ padding: '12px 12px 9px' }}>
              <Input
                placeholder="请输入搜索内容"
                onChange={(e) => {
                  onChangeProductNameSearch(e.target.value);
                }}
                prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
              />
            </div>
            <Table
              style={{}}
              rowKey={'key'}
              size="small"
              rowSelection={{
                getCheckboxProps: (item) => ({ disabled: productDisabledKeys?.includes(item.key) || item.disabled }),
                onSelectAll(selected, selectedRows) {
                  console.log('selectedRows', selected, selectedRows);
                  const _ids = selectedRows.map((item) => item.key);
                  setProductKeys(_ids);
                  modalFormRef.current?.setFieldValue('productIds', _ids.join(','));
                },
                onSelect({ key }, selected, selectedRows) {
                  console.log('key', key);
                  const _ids = selectedRows.map((item) => item.key);
                  setProductKeys(_ids);
                  modalFormRef.current?.setFieldValue('productIds', _ids.join(','));
                },
                selectedRowKeys: productKeys,
              }}
              columns={productColmns}
              dataSource={productDatasourceFilter}
            ></Table>
          </ProCard>
        </div>
      </ModalForm>
    </PageContainer>
  );
}

export default AllocationPlanGroupList;
