import { ApartmentOutlined, ContactsOutlined } from '@ant-design/icons';
import { ActionType, PageContainer, ProCard, ProColumns, ProFormInstance, ProTable } from '@ant-design/pro-components';
import { Tooltip, Tree } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { CustomerApiDto, DepartmentApiDto, EmployeeApiDto, getDepartmentList, getEmployeeList, ProductApiDto } from '../../api/service';
import { listToTree } from '../../utils/common';

interface departmentTreeDataType extends DepartmentApiDto {
  id: number;
  key: number;
  title: string;
  upId: number;
}

function Employee() {
  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);
  const [employeeDataSource, setEmployeeDataSource] = useState<EmployeeApiDto[]>([]);
  const [currentDeptIds, setCurrentDeptIds] = useState<number[]>();
  const [currentPostIds, setCurrentPostIds] = useState<number[]>();
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const [visible, setVisible] = useState<boolean>(false);
  const updateEmployeeFormRef = useRef<ProFormInstance>();

  const productActionRef = useRef<ActionType>();
  const productFormRef = useRef<ProFormInstance>();

  const customerActionRef = useRef<ActionType>();
  const customerFormRef = useRef<ProFormInstance>();

  const [productSelectedKeys, setProductSelectedKeys] = useState<any[]>();
  const [customerSelectedKeys, setCustomerSelectedKeys] = useState<any[]>();

  async function getDepartmentTreeData() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        const data = result.data;
        // data.forEach(n => n.key = n.id)
        // setDepartmentList(data);
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
      }
    }
  }

  async function doGetEmployeeList() {
    const result = await getEmployeeList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        const data = result.data;
        setEmployeeDataSource(data);
      }
    }
  }

  // useEffect(() => {
  //   actionRef.current?.reload();
  // }, [employeeDataSource]);

  // useEffect(() => {
  //   doGetEmployeeList();
  // }, [currentDeptId]);

  useEffect(() => {
    getDepartmentTreeData();
  }, []);

  useEffect(() => {
    if (visible) {
      setProductSelectedKeys([]);
      setCustomerSelectedKeys([]);
    } else {
    }
  }, [visible]);

  const columns: ProColumns<EmployeeApiDto>[] = [
    {
      title: '工号',
      key: 'userCode',
      dataIndex: 'userCode',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      hideInSearch: true,
    },
    {
      title: '岗位',
      dataIndex: 'postName',
      hideInSearch: true,
    },
    {
      title: '所属部门',
      dataIndex: 'deptId',
      render: (value, record) => {
        return <>{record.deptName || '-'}</>;
      },
      hideInSearch: true,
    },
    {
      title: '关联客户数',
      dataIndex: 'customerList',
      align: 'center',
      render: (value, record) => {
        const title = <div>{record.customerList && record.customerList.map((n) => <p>{n.name}</p>)}</div>;
        return (
          <>
            {record.customerList && record.customerList.length > 0 ? (
              <Tooltip placement="top" title={title}>
                <a>{record.customerList.length}</a>
              </Tooltip>
            ) : (
              <a>0</a>
            )}
          </>
        );
      },
      hideInSearch: true,
    },
    // {
    //   title: '关联产品数',
    //   dataIndex: 'productList',
    //   align: 'center',
    //   render: (value, record) => {
    //     const title = <div>{record.productList && record.productList.map((n) => <p>{n.productName}</p>)}</div>;
    //     return (
    //       <Tooltip placement="top" title={title}>
    //         <a>{record.productList ? record.productList.length : 0}</a>
    //       </Tooltip>
    //     );
    //   },
    //   hideInSearch: true,
    // },
    // {
    //   title: '操作',
    //   key: 'option',
    //   width: 60,
    //   valueType: 'option',
    //   render: (text: any, record: any) => {
    //     return (
    //       <Button
    //         type="link"
    //         onClick={() => {
    //           setVisible(true);
    //           const _productSelectedKeys = record.productList ? record.productList.map((n: { productId: any }) => n.productId) : [];
    //           const _customerSelectedKeys = record.customerList ? record.customerList.map((n: { userId: any }) => n.userId) : [];
    //           setTimeout(() => {
    //             updateEmployeeFormRef.current?.setFieldsValue({
    //               ...record,
    //             });
    //             setProductSelectedKeys(_productSelectedKeys);
    //             setCustomerSelectedKeys(_customerSelectedKeys);
    //           });
    //         }}
    //       >
    //         关联产品和客户
    //       </Button>
    //     );
    //   },
    // },
  ];

  // 递归函数 获取所有子级
  function getAllChildren(node: any): DepartmentApiDto[] {
    if (!node.children || node.children.length === 0) {
      return [];
    }

    let allChildren = [];

    // 遍历当前节点的子节点
    for (const child of node.children) {
      console.log('child', child);

      allChildren.push(child);
      // 递归调用，获取子节点的所有子节点
      allChildren = allChildren.concat(getAllChildren(child));
    }

    return allChildren;
  }

  const productColmns: ProColumns<ProductApiDto>[] = [
    {
      dataIndex: 'productName',
      title: '产品名称',
      ellipsis: true,
    },
    {
      dataIndex: 'productLineName',
      title: '产品线',
      ellipsis: true,
    },
  ];

  const customerColumns: ProColumns<CustomerApiDto>[] = [
    {
      title: '客户编号',
      dataIndex: 'userId',
    },
    {
      title: '客户名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '联系方式',
      dataIndex: 'mobile',
      ellipsis: true,
    },
  ];

  return (
    <PageContainer title="人员列表">
      <ProCard style={{ marginBlockStart: 8 }} gutter={8} ghost>
        <ProCard colSpan="18%" bordered title="部门 / 岗位信息">
          <Tree
            showLine
            showIcon
            fieldNames={{
              key: 'id',
              title: 'name',
            }}
            icon={(props: any) => {
              return props.type === 1 ? <ApartmentOutlined /> : <ContactsOutlined />;
            }}
            defaultExpandAll
            key={'key'}
            onSelect={(selectedKeys: React.Key[], info: any) => {
              console.log(selectedKeys);
              console.log(info.selectedNodes);
              // 当前和所有子级数据平铺
              let _selectedKeys = (selectedKeys as number[]) || [];
              if (info.selectedNodes[0] && info.selectedNodes[0].type === 1) {
                if (info.selectedNodes[0] && info.selectedNodes[0].children) {
                  const list = getAllChildren(info.selectedNodes[0]);
                  if (list && list.length > 0) {
                    _selectedKeys = [..._selectedKeys, ...list.map((n) => n.id)];
                  }
                }
                setCurrentDeptIds(_selectedKeys);
                setCurrentPostIds([]);
              } else if (info.selectedNodes[0] && info.selectedNodes[0].type === 2) {
                _selectedKeys = [info.selectedNodes[0].id];
                setCurrentDeptIds([]);
                setCurrentPostIds(_selectedKeys);
              } else {
                setCurrentDeptIds([]);
                setCurrentPostIds([]);
              }
            }}
            treeData={departmentTreeData}
          />
        </ProCard>
        <ProCard bordered>
          <ProTable
            actionRef={actionRef}
            formRef={formRef}
            rowKey={'ssoUserId'}
            columns={columns}
            toolBarRender={false}
            params={{
              ssoDeptId: currentDeptIds,
              postId: currentPostIds,
            }}
            // dataSource={employeeDataSource}
            request={async (params: any, sorter, filter): Promise<any> => {
              console.log('查询', params, sorter, filter);

              const result = await getEmployeeList();
              if (result?.success) {
                if (result.data && result.data.length > 0) {
                  const datasouce = result.data;
                  let data: EmployeeApiDto[] = datasouce.filter((item) => {
                    // 默认通过所有条件
                    let passUserCode = true;
                    let passName = true;
                    // 值来自于tree
                    let passSsoDeptId = true;
                    // 值来自table form
                    let passDeptId = true;
                    // 判断是否有 userCode 条件，如果有则进行判断
                    let passPostId = true;
                    if (params.userCode) {
                      passUserCode = item.userCode?.includes(params.userCode);
                    }
                    // 判断是否有 name 条件，如果有则进行判断
                    if (params.name) {
                      passName = item.name?.includes(params.name);
                    }
                    // 判断是否有 ssoDeptId 条件，如果有则进行判断
                    if (params.ssoDeptId && params.ssoDeptId.length > 0) {
                      passSsoDeptId = params.ssoDeptId.includes(item.ssoDeptId);
                    }
                    if (params.postId && params.postId.length > 0) {
                      passPostId = params.postId.includes(item.postId);
                    }
                    // 通过所有条件的项将被保留
                    return passUserCode && passName && passSsoDeptId && passDeptId && passPostId;
                  });
                  return {
                    data: data,
                    success: true,
                  };
                }
              }
            }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              hideOnSinglePage: false,
            }}
          />
        </ProCard>
      </ProCard>
      {/* <ModalForm
        title="关联产品和客户"
        open={visible}
        autoFocusFirstInput
        modalProps={{
          onCancel: () => setVisible(false),
        }}
        width={'80%'}
        formRef={updateEmployeeFormRef}
        submitTimeout={2000}
        onFinish={async (values) => {
          console.log('values', values);

          if (!productSelectedKeys || productSelectedKeys.length === 0) {
            message.error('请至少选择一个产品');
            return;
          }

          if (!customerSelectedKeys || customerSelectedKeys.length === 0) {
            message.error('请至少选择一个客户');
            return;
          }

          const params: UpdateEmployeeSettingDto = {
            employeeId: values.ssoUserId as number,
            productIds: productSelectedKeys.join(','),
            customerIds: customerSelectedKeys.join(','),
          };
          console.log('params', params);
          const result = await bindingEmployee(params);
          if (result?.success) {
            message.success('操作成功');
            setVisible(false);
            actionRef.current?.reload();
            return true;
          } else {
            return false;
          }
        }}
      >
        <ProFormText name="ssoUserId" hidden />
        <ProCard title="" split="vertical" gutter={100}>
          <ProCard title="产品列表">
            <ProTable<ProductApiDto>
              actionRef={productActionRef}
              formRef={productFormRef}
              rowKey={'productId'}
              columns={productColmns}
              toolbar={{
                multipleLine: false,
                settings: [],
              }}
              rowSelection={{
                selectedRowKeys: productSelectedKeys,
                onChange(selectedRowKeys, selectedRows, info) {
                  setProductSelectedKeys(selectedRowKeys);
                  console.log('selectedRowKeys', selectedRowKeys, 'selectedRows', selectedRows, 'info', info);
                },
              }}
              search={{
                filterType: 'light',
              }}
              request={async (params: any, sorter, filter): Promise<any> => {
                console.log('查询', params, sorter, filter);
                const result = await getProductList();
                if (result?.success) {
                  let data = result.data;
                  if (data) {
                    data = data.filter((item) => {
                      // 默认通过所有条件
                      let passProductName = true;
                      let passProductLineName = true;
                      if (params.productName) {
                        passProductName = item.productName?.includes(params.productName);
                      }
                      // 判断是否有 name 条件，如果有则进行判断
                      if (params.productLineName) {
                        passProductLineName = item.productLineName?.includes(params.productLineName);
                      }
                      // 通过所有条件的项将被保留
                      return passProductName && passProductLineName;
                    });
                  }
                  return {
                    data: data,
                    success: true,
                  };
                }
              }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                hideOnSinglePage: false,
              }}
            />
          </ProCard>
          <ProCard title="客户列表">
            <ProTable
              actionRef={customerActionRef}
              formRef={customerFormRef}
              rowKey={'userId'}
              columns={customerColumns}
              toolbar={{
                multipleLine: false,
                settings: [],
              }}
              rowSelection={{
                selectedRowKeys: customerSelectedKeys,
                onChange(selectedRowKeys, selectedRows, info) {
                  setCustomerSelectedKeys(selectedRowKeys);
                  console.log('selectedRowKeys', selectedRowKeys, 'selectedRows', selectedRows, 'info', info);
                },
              }}
              search={{
                filterType: 'light',
              }}
              request={async (params: any, sorter, filter): Promise<any> => {
                console.log('查询', params, sorter, filter);
                const result = await getCustomerList();
                if (result?.success) {
                  let data = result.data;
                  if (data) {
                    data = data.filter((item) => {
                      // 默认通过所有条件
                      let passUserId = true;
                      let passName = true;
                      let passMobile = true;
                      if (params.userId) {
                        passUserId = item.userId?.includes(params.userId);
                      }
                      if (params.name) {
                        passName = item.name?.includes(params.name);
                      }
                      if (params.mobile) {
                        passMobile = item.mobile?.includes(params.mobile);
                      }
                      // 通过所有条件的项将被保留
                      return passUserId && passName && passMobile;
                    });
                  }
                  return {
                    data: data,
                    success: true,
                  };
                }
              }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                hideOnSinglePage: false,
              }}
            />
          </ProCard>
        </ProCard>
      </ModalForm> */}
    </PageContainer>
  );
}

export default Employee;
