// custom-instance.ts 嵌入 orval 配置中
import { message } from 'antd';
import Axios, { AxiosRequestConfig } from 'axios';
import store from '../state/store';

const _baseURL = 'https://g-abacus-sit.dolinkhub.com/api';
// const _baseURL = 'http://gaapi.ulitom.com';
export const AXIOS_INSTANCE = Axios.create({ baseURL: _baseURL, timeout: 6000 }); // use your own URL here or environment variable

AXIOS_INSTANCE.defaults.headers.common['Content-Type'] = 'application/json';

let sessionExpired = false;

// add a second `options` argument here if you want to pass extra options to each generated query
export const axiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const token = store.getState().app.token;

  const controller = new AbortController();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    signal: controller.signal,
    headers: {
      'Authorization-Token': token,
    },
  })
    .then(({ data }) => {
      console.log('data', data);
      if (data && !data.success) {
        if (data.code === '1002') {
          if (!sessionExpired) {
            message.error(data.message);
            sessionExpired = true;
          } else if (sessionExpired) {
            controller.abort();
            setTimeout(() => {
              sessionExpired = false;
            }, 1000);
          }
        } else {
          console.log(data.message);

          message.error(data.message);
        }
      }
      return data;
    })
    .catch((error) => {
      console.log('error', error);
      if (error.code === 'ERR_NETWORK') {
        message.error('网络异常');
      } else if (error.code === 'ECONNABORTED') {
        message.error('请求超时');
      } else {
        message.error(error.message);
      }
    });

  // @ts-ignore
  promise.cancel = () => {
    controller.abort();
  };

  return promise;
};
