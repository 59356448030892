import { ActionType, ModalForm, PageContainer, ProCard, ProColumns, ProFormDatePicker, ProFormInstance, ProFormText, ProTable } from '@ant-design/pro-components';
import { injectGlobal } from '@emotion/css';
import { Button, Form, Input, InputNumber, message, Popconfirm, Space, Table, TableColumnsType, theme, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import {
  cancelCostInput,
  confirmCostInput,
  CostInputRecordDetailDto,
  CostInputRecordDto,
  createCostInput,
  deleteCostInput,
  getCostInputPage,
  getDepartmentList,
  updateCostInput,
  UpdateCostInputRecordDto,
} from '../../api/service';
import { formatProTableQueryData, formatProTableReulstData } from '../../utils/ant-util';
import { flattenTree, listToTree, numberFormat } from '../../utils/common';
const { useToken } = theme;

injectGlobal`
  .cost {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
`;

enum Action {
  ADD = '新建成本记录',
  EDIT = '编辑成本记录',
}

function CostInputList() {
  const actionRef = useRef<ActionType>();

  const formRef = useRef<ProFormInstance>();
  const [visible, setVisible] = useState<boolean>(false);
  // 当前操作
  const [currentAction, setCurrentAction] = useState<Action>();
  const [departmentFlattenTreeData, setDepartmentFlattenTreeData] = useState<any[]>([]);
  const [deptCostInputAmount, setDeptCostInputAmount] = useState<{ [x: string]: number }>();
  const [deptCostInputTypeAmount, setDeptCostInputTypeAmount] = useState<{ [x: string]: number }>();
  const [departmentList, setDepartmentList] = useState();
  const [deptCostInputDetailForm] = Form.useForm();

  const { token } = useToken();

  const toAdd = () => {
    setVisible(true);
    setCurrentAction(Action.ADD);
    formRef?.current?.resetFields();
    console.log('departmentFlattenTreeData', departmentFlattenTreeData);
    // 初始化成本明细
    setTimeout(() => {
      deptCostInputDetailForm.setFieldsValue({
        deptCostInputDetailList: [...departmentFlattenTreeData],
      });
    });
  };

  const toEdit = (record: CostInputRecordDto) => {
    setVisible(true);

    const _departmentFlattenTreeData = JSON.parse(JSON.stringify(departmentFlattenTreeData));
    _departmentFlattenTreeData.forEach(
      (n: {
        deptId: number;
        salary: number | undefined;
        reception: number | undefined;
        travel: number | undefined;
        other: number | undefined;
        purchaseOutworker: number | undefined;
        purchaseAsset: number | undefined;
        loanedWorker: number | undefined;
      }) => {
        record.deptCostDetailList?.forEach((nn) => {
          if (n.deptId === nn.deptId) {
            n.salary = nn.salary;
            n.reception = nn.reception;
            n.travel = nn.travel;
            n.other = nn.other;
            n.purchaseOutworker = nn.purchaseOutworker;
            n.purchaseAsset = nn.purchaseAsset;
            n.loanedWorker = nn.loanedWorker;
          }
        });
      },
    );
    let deptTotalAmount: { [x: string]: number } = {};
    let costTypeAmount: { [x: string]: number } = {};

    _departmentFlattenTreeData.forEach((n: any, index: any) => {
      if (index > 0) {
        const _deptTotalAmount = n.salary || 0 + n.reception || 0 + n.travel || 0 + n.other || 0 + n.purchaseOutworker || 0 + n.purchaseAsset || 0 + n.loanedWorker || 0;
        deptTotalAmount[index + ''] = _deptTotalAmount;
        const includeKeys = ['salary', 'reception', 'travel', 'other', 'purchaseOutworker', 'purchaseAsset', 'loanedWorker'];
        for (let key in n) {
          if (includeKeys.includes(key)) {
            if (costTypeAmount[key]) {
              costTypeAmount[key] += n[key] || 0;
            } else {
              costTypeAmount[key] = n[key] || 0;
            }
          }
        }
      }
    });

    // 设置部门合计
    setDeptCostInputAmount(deptTotalAmount);
    // 统计成本类型小计
    setDeptCostInputTypeAmount(costTypeAmount);
    setTimeout(() => {
      const defaultParams = {
        ...record,
      };
      formRef?.current?.setFieldsValue(defaultParams);

      deptCostInputDetailForm.setFieldsValue({
        deptCostInputDetailList: [..._departmentFlattenTreeData],
      });
    }, 100);
  };

  const doDelete = async (costInputCode: string) => {
    const result = await deleteCostInput(costInputCode);
    if (result?.success) {
      message.success('删除成功');
      actionRef.current?.reload();
    }
  };

  const doConfirm = async (costInputCode: string) => {
    const result = await confirmCostInput(costInputCode);
    if (result?.success) {
      message.success('提交成功');
      actionRef.current?.reload();
    }
  };

  const doCancel = async (costInputCode: string) => {
    const result = await cancelCostInput(costInputCode);
    if (result?.success) {
      message.success('撤销成功');
      actionRef.current?.reload();
    }
  };

  async function getDepartmentTreeData() {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        let data: any = result.data;
        data = data.filter((n: { type: number }) => n.type === 1);
        data = data.map((n: { deptId: any; id: any; deptName: any; name: any }) => {
          n.deptId = n.id;
          n.deptName = n.name;
          return n;
        });
        setDepartmentList(JSON.parse(JSON.stringify(data)));
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        // 将tree数据按照部门顺序进行平铺
        setDepartmentFlattenTreeData(flattenTree(newItem));
      }
    }
  }

  useEffect(() => {
    getDepartmentTreeData();
  }, []);

  useEffect(() => {
    // 初始化方案详情
    if (visible) {
    } else {
    }
  }, [visible]);

  const columns: ProColumns<any>[] = [
    {
      title: '录入单号',
      dataIndex: 'costInputCode',
      render: (_, record) => {
        return (
          <Tooltip title={record.costInputCode}>
            <span>{'...' + record.costInputCode.slice(-4)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '薪资(不含激励)',
      dataIndex: 'salary',
      valueType: 'money',
      hideInSearch: true,
    },
    {
      title: '招待费',
      dataIndex: 'reception',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '差旅费',
      dataIndex: 'travel',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '其他分摊',
      dataIndex: 'other',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '采购(人员外包)',
      dataIndex: 'purchaseOutworker',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '采购(软硬件)',
      dataIndex: 'purchaseAsset',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '内部借调人工',
      dataIndex: 'loanedWorker',
      hideInSearch: true,
      valueType: 'money',
    },
    {
      title: '总计',
      dataIndex: 'totalAmount',
      valueType: 'money',
      hideInSearch: true,
    },
    {
      title: '月份',
      dataIndex: 'month',
      hideInSearch: true,
      render: (_: any, record: { month: any }) => {
        return record.month ? dayjs(record.month).format('YYYY-MM') : '';
      },
    },
    {
      title: '月份',
      dataIndex: 'month',
      valueType: 'dateMonth',
      hideInTable: true,
      formItemProps: {
        // initialValue: dayjs(dayjs().format('YYYY-MM')).toDate().getTime(),
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      valueEnum: {
        '-1': {
          text: '作废',
        },
        0: {
          text: '草稿',
        },
        1: {
          text: '正常',
        },
      },
    },
    {
      title: '创建人',
      dataIndex: 'createdByName',
      align: 'center',
      hideInSearch: true,
    },
    {
      title: '操作时间',
      dataIndex: 'updateAt',
      valueType: 'dateTime',
      hideInSearch: true,
    },
    {
      title: '操作时间',
      dataIndex: 'updateAt',
      valueType: 'dateRange',
      hideInTable: true,
      fieldProps(form, config) {
        return {
          variant: 'outlined',
        };
      },
    },
    {
      title: '操作',
      valueType: 'option',
      render: (_, record) => {
        return (
          <Space>
            {record.status === 0 ? (
              <>
                <Popconfirm title="确定删除吗？" onConfirm={() => doDelete(record.costInputCode)}>
                  <a>删除</a>
                </Popconfirm>
                <a onClick={() => toEdit(record)}>编辑</a>
                <Popconfirm title="确定提交吗？" onConfirm={() => doConfirm(record.costInputCode)}>
                  <a>提交</a>
                </Popconfirm>
              </>
            ) : (
              <>
                {record.status === 1 ? (
                  <Popconfirm title="确定撤销吗？" onConfirm={() => doCancel(record.costInputCode)}>
                    <a>撤销</a>
                  </Popconfirm>
                ) : null}
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const doSaveCostInputDraft = async () => {
    console.log('save draft');

    try {
      // 同步代码
      const validateFields = await formRef.current?.validateFields();
    } catch (error) {
      return;
    }
    const values = await formRef.current?.getFieldsValue();
    if (values) {
      const deptCostInputDetailValues = deptCostInputDetailForm.getFieldsValue();
      const deptCostInputList = deptCostInputDetailValues.deptCostInputDetailList.filter(
        (n: { salary: any; reception: any; travel: any; other: any; purchaseOutworker: any; purchaseAsset: any; loanedWorker: any }) =>
          (n.salary || 0) + (n.reception || 0) + (n.travel || 0) + (n.other || 0) + (n.purchaseOutworker || 0) + (n.purchaseAsset || 0) + (n.loanedWorker || 0) > 0,
      );
      if (deptCostInputList.length === 0) {
        message.error('请填写部门成本明细');
        return;
      }
      const _deptCostInputList = deptCostInputList.map(
        (n: { path: any[]; deptName: string; deptId: any; salary: any; reception: any; travel: any; other: any; purchaseOutworker: any; purchaseAsset: any; loanedWorker: any }) => {
          return {
            deptName: n.path.map((n) => n.name).join('/'),
            deptId: n.deptId,
            salary: n.salary,
            reception: n.reception,
            travel: n.travel,
            other: n.other,
            purchaseOutworker: n.purchaseOutworker,
            purchaseAsset: n.purchaseAsset,
            loanedWorker: n.loanedWorker,
          };
        },
      );
      const params: any = {
        ...values,
        month: dayjs(values.month).toDate().getTime(),
        deptCostDetailList: _deptCostInputList,
        status: 0,
      };
      if (!values.costInputCode) {
        const result = await createCostInput(params);
        if (result.success) {
          message.success('保存成功');
          setVisible(false);
          actionRef.current?.reload();
          return true;
        } else {
          message.error(result.message);
        }
      } else {
        const result = await updateCostInput(params);
        if (result.success) {
          message.success('保存成功');
          setVisible(false);
          actionRef.current?.reload();
          return true;
        } else {
          message.error(result.message);
        }
      }
    }
  };

  const expandedRowRender = (list: any[]) => {
    const columns: TableColumnsType<CostInputRecordDetailDto> = [
      {
        title: '部门',
        dataIndex: 'deptName',
        key: 'deptName',
        render: (value, record) => {
          const parts = record.deptName?.split('/');
          let result = record.deptName;
          if (parts && parts.length > 2) {
            result = parts.slice(1).join('/');
          }
          return result;
        },
      },
      {
        title: '薪资(不含激励)',
        dataIndex: 'salary',
        key: 'salary',
        render: (value, record) => {
          return numberFormat(record.salary || 0, 2);
        },
      },
      {
        title: '招待费',
        dataIndex: 'reception',
        key: 'reception',
        render: (value, record) => {
          return numberFormat(record.reception || 0, 2);
        },
      },
      {
        title: '差旅费',
        dataIndex: 'travel',
        key: 'travel',
        render: (value, record) => {
          return numberFormat(record.travel || 0, 2);
        },
      },
      {
        title: '其他分摊',
        dataIndex: 'other',
        key: 'other',
        render: (value, record) => {
          return numberFormat(record.other || 0, 2);
        },
      },
      {
        title: '采购(人员外包)',
        dataIndex: 'purchaseOutworker',
        key: 'purchaseOutworker',
        render: (value, record) => {
          return numberFormat(record.purchaseOutworker || 0, 2);
        },
      },
      {
        title: '采购(软硬件)',
        dataIndex: 'purchaseAsset',
        key: 'purchaseAsset',
        render: (value, record) => {
          return numberFormat(record.purchaseAsset || 0, 2);
        },
      },
      {
        title: '内部借调人工',
        dataIndex: 'loanedWorker',
        key: 'loanedWorker',
        render: (value, record) => {
          return numberFormat(record.loanedWorker || 0, 2);
        },
      },
      {
        title: '小计',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (value, record) => {
          return numberFormat(record.totalAmount || 0, 2);
        },
      },
    ];
    return <Table columns={columns} dataSource={list} pagination={false} />;
  };

  return (
    <PageContainer title="录入记录">
      <ProTable
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'id'}
        columns={columns}
        dateFormatter="number"
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record.deptCostDetailList),
          defaultExpandedRowKeys: ['0'],
          onExpand: (record) => {
            console.log(record);
          },
        }}
        toolBarRender={() => [
          <Button key="primary" type="primary" onClick={toAdd}>
            新增成本
          </Button>,
        ]}
        toolbar={{ title: '', settings: [] }}
        request={async (params, sorter): Promise<any> => {
          let query = {
            ...params,
          };
          if (params.deptId) {
            query.deptId = params.deptId[params.deptId.length - 1];
          }
          console.log('query', query);

          const result = await getCostInputPage(formatProTableQueryData(query));
          return formatProTableReulstData(result);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
      <ModalForm<UpdateCostInputRecordDto>
        title={currentAction}
        open={visible}
        autoFocusFirstInput
        modalProps={{
          onCancel: () => setVisible(false),
        }}
        width={'90%'}
        formRef={formRef}
        dateFormatter="number"
        submitTimeout={2000}
        submitter={{
          // submitButtonProps: {
          //   name: '提交',
          // },
          render: (props, defaultDoms) => {
            return [
              <Button type="dashed" key="保存草稿" onClick={doSaveCostInputDraft}>
                保存草稿
              </Button>,
              ...defaultDoms,
            ];
          },
        }}
        onFinish={async (values) => {
          const deptCostInputDetailValues = deptCostInputDetailForm.getFieldsValue();
          const deptCostInputList = deptCostInputDetailValues.deptCostInputDetailList.filter(
            (n: { salary: any; reception: any; travel: any; other: any; purchaseOutworker: any; purchaseAsset: any; loanedWorker: any }) =>
              (n.salary || 0) + (n.reception || 0) + (n.travel || 0) + (n.other || 0) + (n.purchaseOutworker || 0) + (n.purchaseAsset || 0) + (n.loanedWorker || 0) > 0,
          );
          if (deptCostInputList.length === 0) {
            message.error('请填写部门成本明细');
            return;
          }
          const _deptCostInputList = deptCostInputList.map(
            (n: { path: any[]; deptName: string; deptId: any; salary: any; reception: any; travel: any; other: any; purchaseOutworker: any; purchaseAsset: any; loanedWorker: any }) => {
              return {
                deptName: n.path.map((n) => n.name).join('/'),
                deptId: n.deptId,
                salary: n.salary,
                reception: n.reception,
                travel: n.travel,
                other: n.other,
                purchaseOutworker: n.purchaseOutworker,
                purchaseAsset: n.purchaseAsset,
                loanedWorker: n.loanedWorker,
              };
            },
          );
          const params: any = {
            ...values,
            month: dayjs(values.month).toDate().getTime(),
            deptCostDetailList: _deptCostInputList,
            status: 1,
          };
          if (!values.costInputCode) {
            const result = await createCostInput(params);
            if (result.success) {
              message.success('提交成功');
              setVisible(false);
              actionRef.current?.reload();
              return true;
            } else {
              message.error(result.message);
            }
          } else {
            const result = await updateCostInput(params);
            if (result.success) {
              message.success('提交成功');
              setVisible(false);
              actionRef.current?.reload();
              return true;
            } else {
              message.error(result.message);
            }
          }
        }}
      >
        <ProCard title="基础信息" bodyStyle={{ paddingBlock: 0, paddingTop: 16 }}>
          <div style={{ display: 'flex', gap: 16 }}>
            <div>
              <ProFormDatePicker
                name="month"
                label="成本月份"
                width={'md'}
                fieldProps={{
                  picker: 'month',
                  format: 'YYYY-MM',
                  variant: 'outlined',
                }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              ></ProFormDatePicker>
            </div>
            <div style={{ flex: 1 }}>
              <ProFormText
                style={{ flex: 1 }}
                label="备注"
                name="remark"
                rules={[
                  {
                    max: 200,
                  },
                ]}
              ></ProFormText>
              <ProFormText style={{ flex: 1 }} label="批次号" name="costInputCode" hidden></ProFormText>
            </div>
          </div>
        </ProCard>
        <ProCard
          title="成本明细"
          className="cost"
          actions={
            <div style={{ display: 'flex', padding: '0px 24px 0' }}>
              <div style={{ flexBasis: 196, paddingTop: 4 }}>{'小计'}</div>
              <div style={{ flex: 1, display: 'flex', gap: 8, boxSizing: 'border-box' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{numberFormat(deptCostInputTypeAmount?.salary || 0)}</div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{numberFormat(deptCostInputTypeAmount?.reception || 0)}</div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{numberFormat(deptCostInputTypeAmount?.travel || 0)}</div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{numberFormat(deptCostInputTypeAmount?.other || 0)}</div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{numberFormat(deptCostInputTypeAmount?.purchaseOutworker || 0)}</div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{numberFormat(deptCostInputTypeAmount?.purchaseAsset || 0)}</div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{numberFormat(deptCostInputTypeAmount?.loanedWorker || 0)}</div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  {/* <div>总计: </div> */}
                  <div>
                    {numberFormat(
                      (deptCostInputTypeAmount?.salary || 0) +
                        (deptCostInputTypeAmount?.reception || 0) +
                        (deptCostInputTypeAmount?.travel || 0) +
                        (deptCostInputTypeAmount?.other || 0) +
                        (deptCostInputTypeAmount?.purchaseOutworker || 0) +
                        (deptCostInputTypeAmount?.purchaseAsset || 0) +
                        (deptCostInputTypeAmount?.loanedWorker || 0),
                    )}
                  </div>
                  <div style={{ padding: '0 10px' }}>元</div>
                </div>
              </div>
            </div>
          }
        >
          <Form
            form={deptCostInputDetailForm}
            name="dept_cost_input_detail_form"
            autoComplete="off"
            onFieldsChange={(changedFields: any[], allFields: any[]) => {
              console.log('allFields', allFields);
              let deptTotalAmount: { [x: string]: number } = {};
              let costTypeAmount: { [x: string]: number } = {};
              const excludeFields = ['deptId', 'deptName', 'totalAmount'];
              allFields.forEach((n, i) => {
                console.log('n.name', n.name);
                if (Array.isArray(n.name)) {
                  const _name: string = n.name
                    .map((n: string) => {
                      n = String(n);
                      return n;
                    })
                    .join(',');
                  const _nameArr = _name.split(',');
                  // 排除掉的字段 deptId deptName totalAmount
                  if (_nameArr.length === 3 && !excludeFields.includes(_nameArr[2])) {
                    const level = _nameArr[1];
                    const field = _nameArr[2];
                    if (deptTotalAmount[level + '']) {
                      deptTotalAmount[level + ''] += Number(n.value || 0);
                    } else {
                      deptTotalAmount[level + ''] = Number(n.value || 0);
                    }
                    if (costTypeAmount[field]) {
                      costTypeAmount[field] += Number(n.value || 0);
                    } else {
                      costTypeAmount[field] = Number(n.value || 0);
                    }
                  }
                }
              });
              // 设置部门合计
              setDeptCostInputAmount(deptTotalAmount);
              // 统计成本类型小计
              setDeptCostInputTypeAmount(costTypeAmount);
            }}
          >
            <Form.List name="deptCostInputDetailList">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field, index) => {
                    const item = deptCostInputDetailForm.getFieldsValue()['deptCostInputDetailList'][index];
                    return (
                      <div style={{ marginLeft: item.level * 20, paddingTop: 4 }} key={item.id}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flexBasis: item.level * -20 + 200, paddingTop: 4 }}>{item.name}</div>
                          {item.level === 0 ? (
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', textAlign: 'center', gap: 8 }}>
                              <div style={{ flex: 1 }}>薪资(不含激励)</div>
                              <div style={{ flex: 1 }}>招待费</div>
                              <div style={{ flex: 1 }}>差旅费</div>
                              <div style={{ flex: 1 }}>其他分摊费</div>
                              <div style={{ flex: 1 }}>采购费(人员外包)</div>
                              <div style={{ flex: 1 }}>采购费(软硬件)</div>
                              <div style={{ flex: 1 }}>内部借调人工费</div>
                              <div style={{ flex: 1 }}>部门合计</div>
                            </div>
                          ) : (
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', gap: 8 }}>
                              <Form.Item name={[field.name, 'deptId']} style={{ flex: 1 }} hidden>
                                <Input value={item.deptId} />
                              </Form.Item>
                              <Form.Item name={[field.name, 'deptName']} style={{ flex: 1 }} hidden>
                                <Input value={item.deptName} />
                              </Form.Item>
                              <Form.Item name={[field.name, 'salary']} style={{ flex: 1 }}>
                                <InputNumber min={0} max={9999999} precision={2} placeholder="请输入薪资" style={{ width: '100%' }} addonAfter="元" />
                              </Form.Item>
                              <Form.Item name={[field.name, 'reception']} style={{ flex: 1 }}>
                                <InputNumber min={0} max={9999999} precision={2} placeholder="请输入招待费" style={{ width: '100%' }} addonAfter="元" />
                              </Form.Item>
                              <Form.Item name={[field.name, 'travel']} style={{ flex: 1 }}>
                                <InputNumber min={0} max={9999999} precision={2} placeholder="请输入差旅费" style={{ width: '100%' }} addonAfter="元" />
                              </Form.Item>
                              <Form.Item name={[field.name, 'other']} style={{ flex: 1 }}>
                                <InputNumber min={0} max={9999999} precision={2} placeholder="请输入其他分摊费" style={{ width: '100%' }} addonAfter="元" />
                              </Form.Item>
                              <Form.Item name={[field.name, 'purchaseOutworker']} style={{ flex: 1 }}>
                                <InputNumber min={0} max={9999999} precision={2} placeholder="请输入人员外包费" style={{ width: '100%' }} addonAfter="元" />
                              </Form.Item>
                              <Form.Item name={[field.name, 'purchaseAsset']} style={{ flex: 1 }}>
                                <InputNumber min={0} max={9999999} precision={2} placeholder="请输入软硬件费" style={{ width: '100%' }} addonAfter="元" />
                              </Form.Item>
                              <Form.Item name={[field.name, 'loanedWorker']} style={{ flex: 1 }}>
                                <InputNumber min={0} max={9999999} precision={2} placeholder="请输入内部借调人工费" style={{ width: '100%' }} addonAfter="元" />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'totalAmount']}
                                style={{ flex: 1, textAlign: 'right' }}
                                dependencies={['salary', 'reception', 'travel', 'other', 'purchaseOutworker', 'purchaseAsset', 'loanedWorker']}
                              >
                                {/* <Input style={{ width: '100%' }} defaultValue={deptCostInputAmount && deptCostInputAmount[index + '']} disabled addonAfter="元" /> */}
                                <div style={{ border: '1px solid #d9d9d9', borderRadius: '6px', display: 'flex' }}>
                                  <div style={{ flex: 1, padding: '4px 11px', borderRight: '1px solid #d9d9d9' }}>{deptCostInputAmount && numberFormat(deptCostInputAmount[index + ''])}</div>
                                  <div
                                    style={{
                                      background: 'rgba(0, 0, 0, 0.02)',
                                      color: 'rgba(0, 0, 0, 0.88)',
                                      padding: '4px 11px',
                                      textAlign: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    元
                                  </div>
                                </div>
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Form.List>
          </Form>
        </ProCard>
      </ModalForm>
    </PageContainer>
  );
}

export default CostInputList;
