/* app/store.ts */
import { configureStore } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';
// import logger from 'redux-logger';
import appReducer from './app/appSlice';

const PERSISTED_KEYS = ['app'];

const store = configureStore({
  reducer: {
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(save({ states: PERSISTED_KEYS }));
  },
  //
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true }),
});

export default store;

// 从 store 本身推断出 `RootState` 和 `AppDispatch` types
export type RootState = ReturnType<typeof store.getState>;
// 类型推断: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
