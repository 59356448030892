type PageQuery<T> = {
  pageNo: number;
  pageSize: number;
  sort: Record<string, any>;
  filter?: Record<string, any>;
  query: T;
};

type ProTablePageData<T> = {
  current?: number;
  data?: T[];
  success?: boolean;
  pageSize?: number;
  total?: number;
};

export function formatProTableQueryData<T>(params: any): PageQuery<T> {
  const newParams = {
    pageNo: params.current,
    pageSize: params.pageSize,
    filter: params.filter,
    sorter: params.sorter,
  };
  delete params.current;
  delete params.pageSize;
  delete params.filter;
  delete params.sorter;
  const data: any = {
    ...newParams,
    query: { ...params },
  };
  console.log(data);
  return data;
}

export function formatProTableReulstData<T>(res: any): ProTablePageData<T> {
  const pageData = {
    pageSize: res.data?.pageSize,
    current: res.data?.pageNo,
    total: res.data?.total,
    data: res.data?.items,
    success: res.success,
  };
  console.log('res', res);

  return pageData;
}
