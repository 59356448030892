import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  collapsed: boolean;
  theme: {
    colorPrimary: string;
    backgroundColor: string;
  };
  darkMode: boolean;
  token: string;
  currentUser: any;
}

const initialState: AppState = {
  collapsed: false,
  theme: {
    colorPrimary: '#1677ff',
    backgroundColor: '#F5F5F5',
  },
  darkMode: false,
  token: '',
  currentUser: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // 菜单栏收起状态
    setCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
    // 设置主题色
    setColorPrimary: (state, action: PayloadAction<string>) => {
      state.theme.colorPrimary = action.payload;
    },
    setTheme: (state, action) => {
      switch (action.payload) {
        case 'dark':
          state.darkMode = true;
          break;
        case 'light':
          state.darkMode = false;
          break;
        default:
          state.darkMode = true;
          break;
      }
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(setDateAsync.fulfilled, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(setDateAsync.pending, (state, action) => {
      console.log(action.meta);
    });
    builder.addCase(setDateAsync.rejected, (state, action) => {
      console.log(action.error);
    });
  },
});

//
export const setDateAsync = createAsyncThunk('app/setDateAsync', async (data: any) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
  return data;
});

export const { setCollapsed, setColorPrimary, setTheme, setToken, setCurrentUser } = appSlice.actions;
export default appSlice.reducer;
