import { ActionType, ModalForm, PageContainer, ProColumns, ProFormInstance, ProFormSelect, ProFormText, ProTable, RequestOptionsType } from '@ant-design/pro-components';
import { Button, message, Popconfirm, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { createEmployeeRole, deleteEmployeeRole, EmployeeSettingDto, getEmployeeList, getEmployeeRolePage } from '../../api/service';
import { formatProTableQueryData, formatProTableReulstData } from '../../utils/ant-util';

enum Action {
  ADD = '新建分配方案',
  EDIT = '编辑分配方案',
}

function AllocationRecordBill() {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const [currentAction, setCurrentAction] = useState<Action>();
  const [visible, setVisible] = useState<boolean>(false);

  const toAdd = () => {
    setVisible(true);
    setCurrentAction(Action.ADD);
    formRef?.current?.resetFields();
  };

  const doDelete = async (id: number) => {
    const result = await deleteEmployeeRole(id);
    if (result?.success) {
      message.success('删除成功');
      actionRef.current?.reload();
    }
  };

  useEffect(() => {
    // getAccountBankInfo().then((res) => {
    //   console.log(res.data);
    // });
  }, []);

  const columns: ProColumns<EmployeeSettingDto>[] = [
    {
      title: '工号',
      dataIndex: 'employeeId',
      hideInSearch: true,
    },
    {
      title: '人员名称',
      dataIndex: 'employeeName',
    },
    {
      title: '角色类型',
      dataIndex: 'roleType',
      valueEnum: {
        ADMIN: '管理员',
        ORDINARY: '普通用户',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      hideInSearch: true,
      valueType: 'date',
    },
    {
      title: '操作',
      valueType: 'option',
      render: (_, record) => {
        return (
          <Space>
            <Popconfirm title="确定删除吗？" onConfirm={() => doDelete(record.id as number)}>
              <a>删除</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <PageContainer title="用户角色">
      <ProTable
        actionRef={actionRef}
        formRef={formRef}
        rowKey={'id'}
        columns={columns}
        toolbar={{ title: '', settings: [] }}
        toolBarRender={() => [
          <Button key="primary" type="primary" onClick={toAdd}>
            新增人员权限
          </Button>,
        ]}
        request={async (params, sorter): Promise<any> => {
          const result = await getEmployeeRolePage(formatProTableQueryData(params));
          return formatProTableReulstData(result);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
      />
      <ModalForm
        title={currentAction}
        open={visible}
        autoFocusFirstInput
        modalProps={{
          onCancel: () => setVisible(false),
        }}
        width={'50%'}
        formRef={formRef}
        submitTimeout={2000}
        onFinish={async (values) => {
          console.log('values', values);
          const result = await createEmployeeRole(values);
          if (result?.success) {
            setVisible(false);
            actionRef.current?.reload();
          }
          return true;
        }}
      >
        <ProFormText name="id" hidden />
        <ProFormSelect
          name="employeeId"
          label="员工"
          showSearch
          request={async (params, props) => {
            const result = await getEmployeeList();
            let list: RequestOptionsType[] = [];
            if (result?.success) {
              list = result.data?.map((n) => {
                return {
                  key: n.userCode,
                  value: n.ssoUserId,
                  label: n.name,
                };
              }) as RequestOptionsType[];
            }
            return list;
          }}
          placeholder="请选择员工"
          rules={[{ required: true, message: '请选择员工' }]}
        />
        <ProFormSelect
          name="roleType"
          label="角色"
          valueEnum={{
            ADMIN: '管理员',
            ORDINARY: '普通员工',
          }}
          placeholder="请选择角色"
          rules={[{ required: true, message: '请选择角色' }]}
        />
      </ModalForm>
    </PageContainer>
  );
}

export default AllocationRecordBill;
