import { Column, Pie } from '@ant-design/charts';
import { PageContainer, ProCard, ProColumns, ProTable, StatisticCard } from '@ant-design/pro-components';
import { Badge, Cascader, Empty, Radio, Space, Tabs, TabsProps, theme, Tooltip } from 'antd';
import dayjs from 'dayjs';
import RcResizeObserver from 'rc-resize-observer';
import { useEffect, useState } from 'react';
import {
  BillAllocationDetailRecordDto,
  DateAmountDto,
  getBillProductDistribution,
  getDepartmentList,
  getDeptEmployeeBillAllocationRecord,
  getHomeDate,
  getMonthRecordDept,
  getMonthRecordEmployee,
  HomeDateDto,
  ProductAmountDto,
} from '../../api/service';
import { formatProTableQueryData, formatProTableReulstData } from '../../utils/ant-util';
import { listToTree, numberFormat } from '../../utils/common';

const { useToken } = theme;

const { Divider } = StatisticCard;

function Home() {
  const [responsive, setResponsive] = useState(false);
  const [currentTab, setCurrentTab] = useState('employee');
  const [departmentTreeData, setDepartmentTreeData] = useState<any[]>([]);
  const [homeDate, setHomeDate] = useState<HomeDateDto>();
  const [distributeType, setDistributeType] = useState('Count');
  const [distributeDate, setDistributeDate] = useState<ProductAmountDto[]>([]);
  const [monthData, setMonthData] = useState<DateAmountDto[]>([]);

  const { token } = useToken();

  const getDepartmentTreeData = async () => {
    const result = await getDepartmentList();
    if (result?.success) {
      if (result.data && result.data.length > 0) {
        let data = result.data;
        data = data.filter((n) => n.type === 1);
        const newItem = listToTree(data, 'id', 'upId');
        console.log('newItem', newItem);
        setDepartmentTreeData(newItem);
      }
    }
  };

  const doGetHomeDate = async () => {
    const result = await getHomeDate();
    if (result?.success) {
      setHomeDate(result.data);
    }
  };

  const doGetBillProductDistribution = async () => {
    const type = distributeType === 'Count' ? 1 : 2;
    const result = await getBillProductDistribution(type);
    if (result?.success) {
      setDistributeDate(result.data || []);
    }
  };

  const doGetMonthRecordDept = async () => {
    const result = await getMonthRecordDept();
    if (result?.success) {
      setMonthData(result.data || []);
    }
  };

  const doGetMonthRecordEmployee = async () => {
    const result = await getMonthRecordEmployee();
    if (result?.success) {
      setMonthData(result.data || []);
    }
  };

  useEffect(() => {
    if (currentTab === 'employee') {
      doGetMonthRecordEmployee();
    } else {
      doGetMonthRecordDept();
    }
  }, [currentTab]);

  useEffect(() => {
    doGetBillProductDistribution();
  }, [distributeType]);

  useEffect(() => {
    getDepartmentTreeData();
    doGetHomeDate();
  }, []);

  const columns: ProColumns<BillAllocationDetailRecordDto>[] = [
    {
      title: '账单编号',
      dataIndex: 'billId',
      render: (_, record) => {
        return (
          <Tooltip title={record.billId}>
            <span>{'...' + record.billId.slice(-4)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '激励对象',
      dataIndex: 'employeeName',
      render: (_, record) => {
        return record.type === 1 ? record.employeeName : record.deptName;
      },
    },
    {
      title: '对象类型',
      dataIndex: 'type',
      valueEnum: {
        1: '员工',
        2: '部门',
      },
    },
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      ellipsis: true,
      width: 200,
    },
    {
      title: '产品名称',
      dataIndex: 'productName',
      // hideInSearch: true,
      ellipsis: true,
    },
    // {
    //   title: '商品成本',
    //   dataIndex: 'goodsCost',
    //   // hideInSearch: true,
    // },
    {
      title: '采购成本',
      dataIndex: 'goodsCost',
      valueType: 'money',
      hideInSearch: true,
    },
    {
      title: '部门名称',
      dataIndex: 'deptName',
      ellipsis: true,
      render: (value, record) => {
        const parts = record.deptName?.split('/');
        let result = record.deptName;
        if (parts && parts.length > 2) {
          result = parts.slice(1).join('/');
        }
        return result;
      },
      hideInSearch: true,
    },
    {
      title: '部门名称',
      dataIndex: 'deptId',
      renderFormItem: (item) => {
        return <Cascader options={departmentTreeData} key={item.index} fieldNames={{ label: 'name', value: 'id' }} placeholder="请选择" />;
      },
      hideInTable: true,
    },
    {
      title: '方案名称',
      dataIndex: 'allocationPlanName',
      ellipsis: true,
    },
    {
      title: '激励名称',
      dataIndex: 'allocationPlanGroupName',
      ellipsis: true,
    },
    {
      title: '奖励金额',
      dataIndex: 'incentiveAmount',
      valueType: 'money',
      hideInSearch: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      valueType: 'dateTime',
      hideInSearch: true,
      width: 100,
      ellipsis: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAtRange',
      valueType: 'dateRange',
      hideInTable: true,
      fieldProps(form, config) {
        return {
          variant: 'outlined',
        };
      },
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: 'employee',
      label: '我的激励金额',
    },
    {
      key: 'dept',
      label: '部门激励金额',
    },
  ];

  const onTabChange = (value: any) => {
    console.log(value);
    setCurrentTab(value);
  };

  return (
    <PageContainer>
      <RcResizeObserver
        key="resize-observer"
        onResize={(offset) => {
          setResponsive(offset.width < 596);
        }}
      >
        <RcResizeObserver.Collection>
          <ProCard bodyStyle={{ paddingInline: 0 }}>
            <StatisticCard.Group gutter={6}>
              <StatisticCard
                style={{ background: '#fff' }}
                statistic={{
                  title: '我的激励总额',
                  value: numberFormat(homeDate?.totalAmountEmployee || 0),
                  // status: 'processing',
                  description: (
                    <Space>
                      <div>今日</div>
                      <div>{numberFormat(homeDate?.totalAmountEmployeeToday || 0)}</div>
                    </Space>
                  ),
                }}
              />
              <Divider />
              <StatisticCard
                statistic={{
                  title: '部门激励总额',
                  value: numberFormat(homeDate?.totalAmountDept || 0),
                  description: (
                    <Space>
                      <div>今日</div>
                      <div>{numberFormat(homeDate?.totalAmountDeptToday || 0)}</div>
                    </Space>
                  ),
                }}
              />
              <Divider />
              <StatisticCard
                statistic={{
                  title: '部门账单总数',
                  value: numberFormat(homeDate?.totalNumberBill || 0),
                  description: (
                    <Space>
                      <div>今日</div>
                      <div>{numberFormat(homeDate?.totalNumberBillToday || 0)}</div>
                    </Space>
                  ),
                }}
              />
              <Divider />
              <StatisticCard
                statistic={{
                  title: '部门账单总额',
                  value: numberFormat(homeDate?.totalAmountBill || 0),
                  description: (
                    <Space>
                      <div>今日</div>
                      <div>{numberFormat(homeDate?.totalAmountBillToday || 0)}</div>
                    </Space>
                  ),
                }}
              />
            </StatisticCard.Group>
          </ProCard>

          <ProCard style={{ marginTop: 20 }} bodyStyle={{ paddingInline: 0 }} direction="column">
            <ProCard bodyStyle={{ paddingBlock: 0 }}>
              <Tabs defaultActiveKey="employee" items={items} onChange={onTabChange} />
            </ProCard>
            <ProCard bodyStyle={{ paddingBlock: 0 }}>
              <ProCard ghost colSpan={18}>
                <Column
                  {...{
                    height: 350,
                    data: monthData,
                    xField: 'date',
                    yField: 'amount',
                    label: {
                      text: (d: { amount: number }) => `${d.amount}`,
                      textBaseline: 'bottom',
                    },
                    axis: {
                      x: {
                        labelFormatter: (date: number) => {
                          return `${dayjs(date).format('MM-DD')}`;
                        },
                        labelAutoRotate: true,
                        // tickCount: -100,
                      },
                      y: {
                        // labelFormatter: '.0',
                        // title: '金额',
                      },
                    },
                    style: {
                      // 圆角样式
                      radiusTopLeft: 10,
                      radiusTopRight: 10,
                    },
                  }}
                />
              </ProCard>
              <ProCard colSpan={6} headStyle={{ paddingTop: 0 }} bodyStyle={{ paddingBlock: 0, paddingRight: 0 }} title="产品销售额排行">
                {homeDate?.productAmountList && homeDate?.productAmountList.length > 0 ? (
                  homeDate?.productAmountList.map((n, i) => {
                    if (i <= 3) {
                      return (
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20 }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <Badge count={i + 1} color="#000"></Badge>
                            <span>{n.productName}</span>
                          </div>
                          <div>{numberFormat(n.totalAmount || 0)}</div>
                        </div>
                      );
                    } else {
                      return (
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20 }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <Badge count={i + 1} color="#fff" style={{ color: '#000' }}></Badge>
                            <span>{n.productName}</span>
                          </div>
                          <div>{numberFormat(n.totalAmount || 0)}</div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 80,
                    }}
                  >
                    <Empty />
                  </div>
                )}
              </ProCard>
            </ProCard>
          </ProCard>

          <ProCard style={{ marginTop: 20 }} bodyStyle={{ paddingBlock: 0 }}>
            <ProCard colSpan={18} title="账单激励记录" headStyle={{ paddingInline: 0 }} bodyStyle={{ paddingInline: 0 }}>
              <ProTable
                search={false}
                columns={columns}
                toolbar={{ title: '', settings: [] }}
                dateFormatter="number"
                request={async (params, sorter): Promise<any> => {
                  let query = {
                    ...params,
                  };
                  const result = await getDeptEmployeeBillAllocationRecord(formatProTableQueryData(query));
                  return formatProTableReulstData(result);
                }}
                // tableStyle={{
                //   padding: 0,
                // }}
                pagination={{
                  defaultPageSize: 5,
                  showTitle: true,
                  showPrevNextJumpers: true,
                }}
              />
            </ProCard>
            <ProCard
              title="产品账单占比"
              style={{ padding: '0 20px' }}
              colSpan={6}
              bodyStyle={{ paddingInline: 0, paddingBlock: 0 }}
              extra={
                <Radio.Group style={{ marginBottom: 8 }} value={distributeType} onChange={(e) => setDistributeType(e.target.value)}>
                  <Radio.Button value="Count">数量</Radio.Button>
                  <Radio.Button value="Amount">金额</Radio.Button>
                </Radio.Group>
              }
            >
              {distributeDate && distributeDate.length > 0 ? (
                <Pie
                  {...{
                    height: 300,
                    data: distributeDate,
                    angleField: 'totalAmount',
                    colorField: 'productName',
                    paddingRight: 80,
                    innerRadius: 0.6,
                    label: {
                      text: 'value',
                      style: {
                        fontWeight: 'bold',
                      },
                    },
                    legend: {
                      color: {
                        title: false,
                        position: 'right',
                        rowPadding: 5,
                      },
                    },
                    annotations: [
                      {
                        type: 'text',
                        style: {
                          // text: '产品维度激励金额',
                          x: '50%',
                          y: '50%',
                          textAlign: 'center',
                          fontSize: 40,
                          fontStyle: 'bold',
                        },
                      },
                    ],
                  }}
                />
              ) : (
                <Empty style={{ marginTop: 60 }} />
              )}
            </ProCard>
          </ProCard>
        </RcResizeObserver.Collection>
      </RcResizeObserver>
    </PageContainer>
  );
}

export default Home;
